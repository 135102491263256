import { render, staticRenderFns } from "./onlyLight.vue?vue&type=template&id=1fad025d&scoped=true&"
import script from "./onlyLight.vue?vue&type=script&lang=js&"
export * from "./onlyLight.vue?vue&type=script&lang=js&"
import style0 from "./onlyLight.vue?vue&type=style&index=0&id=1fad025d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fad025d",
  null
  
)

export default component.exports