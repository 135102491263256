<template>
  <div>
    <div class="light_main">
      <div class="all_light">
        <h3>灯光总控</h3>
        <div class="all_light_control_main">
          <div class="left">
            <div class="desc">
              <div class="">灯光总控</div>
              <div class="">一键管控</div>
            </div>
          </div>
          <div class="right">
            <img
              @click="setAllLigt"
              v-if="isLightfull"
              src="@/assets/images/btn/on.png"
              alt=""
            />
            <img
              @click="setAllLigt"
              v-else
              src="@/assets/images/btn/off.png"
              alt=""
            />
          </div>
        </div>
        <div class="light_control_list">
          <div
            v-for="item in lightList"
            :key="item.light_key"
            :class="isLightimg(item) ? 'light_item_on' : 'light_item_off'"
          >
            <h1 v-if="item.num === 0" class="no_light">灯光未连接</h1>
            <!-- 单开关 -->
            <div v-if="item.num === 1" class="one_light_switch">
              <div class="one_light_top">
                <div class="one_light_name">{{ item.light_name }}</div>
                <div
                  :class="
                    item.light_status.state == 'ON'
                      ? 'one_light_state_on'
                      : 'one_light_state_off'
                  "
                >
                  {{ item.light_status.state }}
                </div>
              </div>
              <div class="one_light_bottom">
                <img
                  v-if="item.light_status.state == 'ON'"
                  class="one_light_bottom_state"
                  src="@/assets/images/room/light_on.png"
                  alt=""
                />
                <img
                  v-else
                  class="one_light_bottom_state"
                  src="@/assets/images/room/light_off.png"
                  alt=""
                />
                <img
                  @click="handleronelight(item)"
                  v-if="item.light_status.state == 'ON'"
                  class="one_light_bottom_switch"
                  src="@/assets/images/btn/btn_on.png"
                  alt=""
                />
                <img
                  @click="handleronelight(item)"
                  v-else
                  class="one_light_bottom_switch"
                  src="@/assets/images/btn/btn_off.png"
                  alt=""
                />
              </div>
            </div>
            <!-- 两开关 -->
            <div v-if="item.num === 2" class="two_light_switch">
              <div class="two_light_top">
                <div class="two_light_name">{{ item.light_name }}</div>
                <img
                  v-if="isLightimg(item)"
                  class="two_light_state"
                  src="@/assets/images/room/light_on.png"
                  alt=""
                />
                <img
                  v-else
                  class="two_light_state"
                  src="@/assets/images/room/light_off.png"
                  alt=""
                />
              </div>
              <div class="two_light_bottom">
                <!-- 左 -->
                <div class="two_light_switch_item">
                  <div
                    :class="
                      item.light_status.state_l1 == 'ON'
                        ? 'two_light_switch_name_on'
                        : 'two_light_switch_name_off'
                    "
                  >
                    灯光一
                  </div>
                  <img
                    @click="handlertwolight(item, 1)"
                    v-if="item.light_status.state_l1 == 'ON'"
                    class="two_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                  <img
                    @click="handlertwolight(item, 1)"
                    v-else
                    class="two_light_switch_btn"
                    src="@/assets/images/btn/btn_off.png"
                    alt=""
                  />
                </div>
                <!-- 右 -->
                <div class="two_light_switch_item">
                  <div
                    :class="
                      item.light_status.state_l2 == 'ON'
                        ? 'two_light_switch_name_on'
                        : 'two_light_switch_name_off'
                    "
                  >
                    灯光二
                  </div>
                  <img
                    @click="handlertwolight(item, 2)"
                    v-if="item.light_status.state_l2 == 'ON'"
                    class="two_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                  <img
                    @click="handlertwolight(item, 2)"
                    v-else
                    class="two_light_switch_btn"
                    src="@/assets/images/btn/btn_off.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- 三开关 -->
            <div v-if="item.num === 3" class="three_light_switch">
              <div class="three_light_top">
                <div class="three_light_name">{{ item.light_name }}</div>
                <img
                  v-if="isLightimg(item)"
                  class="three_light_state"
                  src="@/assets/images/room/light_on.png"
                  alt=""
                />
                <img
                  v-else
                  class="three_light_state"
                  src="@/assets/images/room/light_off.png"
                  alt=""
                />
              </div>
              <div class="three_light_bottom">
                <!-- 右 -->
                <div class="three_light_switch_item">
                  <div
                    :class="
                      item.light_status.state_left == 'ON'
                        ? 'three_light_switch_name'
                        : 'three_light_switch_name1'
                    "
                  >
                    灯光一
                  </div>
                  <img
                    @click="handlerThreeLight(item, 1)"
                    v-if="item.light_status.state_left == 'ON'"
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                  <img
                    @click="handlerThreeLight(item, 1)"
                    v-else
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_off.png"
                    alt=""
                  />
                </div>
                <!-- 中 -->
                <div class="three_light_switch_item">
                  <div
                    :class="
                      item.light_status.state_center == 'ON'
                        ? 'three_light_switch_name'
                        : 'three_light_switch_name1'
                    "
                  >
                    灯光二
                  </div>
                  <img
                    @click="handlerThreeLight(item, 2)"
                    v-if="item.light_status.state_center == 'ON'"
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                  <img
                    @click="handlerThreeLight(item, 2)"
                    v-else
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_off.png"
                    alt=""
                  />
                </div>
                <!-- 左 -->
                <div class="three_light_switch_item">
                  <div
                    :class="
                      item.light_status.state_right == 'ON'
                        ? 'three_light_switch_name'
                        : 'three_light_switch_name1'
                    "
                  >
                    灯光三
                  </div>
                  <img
                    @click="handlerThreeLight(item, 3)"
                    v-if="item.light_status.state_right == 'ON'"
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                  <img
                    @click="handlerThreeLight(item, 3)"
                    v-else
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_off.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false" class="light_control_list">
          <div v-for="item in 5" :key="item" class="light_item_on">
            <h1 v-if="false" class="no_light">灯光未连接</h1>
            <!-- 单开关 -->
            <div v-if="false" class="one_light_switch">
              <div class="one_light_top">
                <div class="one_light_name">
                  灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯{{ item }}
                </div>
                <div class="one_light_state_on">ON</div>
              </div>
              <div class="one_light_bottom">
                <img
                  class="one_light_bottom_state"
                  src="@/assets/images/room/light_on.png"
                  alt=""
                />
                <img
                  class="one_light_bottom_switch"
                  src="@/assets/images/btn/btn_on.png"
                  alt=""
                />
              </div>
            </div>
            <!-- 两开关 -->
            <div v-if="false" class="two_light_switch">
              <div class="two_light_top">
                <div class="two_light_name">灯灯灯灯灯灯灯{{ item }}</div>
                <img
                  class="two_light_state"
                  src="@/assets/images/room/light_on.png"
                  alt=""
                />
              </div>
              <div class="two_light_bottom">
                <!-- 左 -->
                <div class="two_light_switch_item">
                  <div class="two_light_switch_name_on">灯光一</div>
                  <img
                    class="two_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                </div>
                <!-- 右 -->
                <div class="two_light_switch_item">
                  <div class="two_light_switch_name_on">灯光二</div>
                  <img
                    class="two_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!-- 三开关 -->
            <div v-if="true" class="three_light_switch">
              <div class="three_light_top">
                <div class="three_light_name">
                  灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯灯{{ item }}
                </div>
                <img
                  class="three_light_state"
                  src="@/assets/images/room/light_on.png"
                  alt=""
                />
              </div>
              <div class="three_light_bottom">
                <!-- 右 -->
                <div class="three_light_switch_item">
                  <div class="three_light_switch_name">灯光一</div>
                  <img
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                </div>
                <!-- 中 -->
                <div class="three_light_switch_item">
                  <div class="three_light_switch_name">灯光二</div>
                  <img
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                </div>
                <!-- 左 -->
                <div class="three_light_switch_item">
                  <div class="three_light_switch_name">灯光三</div>
                  <img
                    class="three_light_switch_btn"
                    src="@/assets/images/btn/btn_on.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reqestAPI from "@/service/api/index";
export default {
  name: "LIGHTS",
  props: ["devicesState", "isShowbigLight"],
  data() {
    return {
      isLightfull: false, //灯光全开全关
      room_name: "",
      room_id: "",
      IlluminationList: [], //光照数据
      lightList: [], //灯光列表
      lightState: [], //灯光状态列表
      lightparm: {}, //设置灯光携带数据
      IlluminationTimer: null, //光照强度定时器
      timer: null,
    };
  },
  created() {
           
  },
  methods: {
    //单个开关
    handleronelight(item) {
      if (item.light_status.state == "ON") {
        item.light_status.state = "OFF";
      } else if (item.light_status.state == "OFF") {
        item.light_status.state = "ON";
      }
      this.lightparm = {
        key: item.light_key,
        ...item.light_status,
      };
      this.setLightState(this.lightparm);
    },
    //两个开关
    handlertwolight(item, num) {
      if (num === 1) {
        if (item.light_status.state_l1 === "ON") {
          item.light_status.state_l1 = "OFF";
          item.light_status.state = "OFF";
        } else {
          item.light_status.state_l1 = "ON";
          // item.light_status.state = 'ON'
        }
      } else if (num === 2) {
        if (item.light_status.state_l2 === "ON") {
          item.light_status.state_l2 = "OFF";
        } else {
          item.light_status.state_l2 = "ON";
        }
      }
      this.lightparm = {
        key: item.light_key,
        ...item.light_status,
      };
      this.setLightState(this.lightparm);
    },
    //三个开关
    handlerThreeLight(item, num) {
      if (num === 1) {
        if (item.light_status.state_left === "ON") {
          item.light_status.state_left = "OFF";
          item.light_status.state = "OFF";
        } else {
          item.light_status.state_left = "ON";
          item.light_status.state = "ON";
        }
      } else if (num === 2) {
        if (item.light_status.state_center === "ON") {
          item.light_status.state_center = "OFF";
        } else {
          item.light_status.state_center = "ON";
        }
      } else if (num === 3) {
        if (item.light_status.state_right === "ON") {
          item.light_status.state_right = "OFF";
        } else {
          item.light_status.state_right = "ON";
        }
      }

      this.lightparm = {
        key: item.light_key,
        ...item.light_status,
      };
      this.setLightState(this.lightparm);
      console.log(this.lightparm);
    },
    //灯光右上角图片状态
    isLightimg(item) {
      if (item.num === 3) {
        if (
          item.light_status.state_left == "ON" ||
          item.light_status.state_center == "ON" ||
          item.light_status.state_right == "ON"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (item.num === 2) {
        if (
          item.light_status.state_l1 == "ON" ||
          item.light_status.state_l2 == "ON"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (item.num === 1) {
        if (item.light_status.state == "ON") {
          return true;
        } else {
          return false;
        }
      }
    },
    //设置灯光全开全关状态
    async setAllLigt() {
      try {
        let res;
        if (this.isLightfull) {
          res = await reqestAPI.setAllLigt(this.room_id, 2);
          // console.log('设置灯光状态关');
        } else {
          res = await reqestAPI.setAllLigt(this.room_id, 1);
          // console.log('设置灯光状态开');
        }
        this.isLightfull = !this.isLightfull;
      } catch (error) {
        console.error(error);
      }
    },
    //获取灯光列表
    async getLightList() {
      try {
        this.lightState = [];
        let roomID = this.$route.query.roomID;
        let res = await reqestAPI.getLights(roomID);
        res.data.list.map((item) => {
          let lightnum = 0;
          item.light_status = JSON.parse(item.light_status);
          if (item.light_status) {
            if (item.light_status.state_l1) {
              item.num = 2;
            } else if (item.light_status.state_center) {
              item.num = 3;
            } else {
              item.num = 1;
            }
          }

          //   this.lightState.push({
          //     light_status: JSON.parse(item.light_status),
          //     light_name: item.light_name,
          //     light_key: item.light_key,
          //     num: lightnum,
          //   });
        });
        this.lightList = res.data.list;
        // console.log(this.lightList);
      } catch (error) {
        console.error(error);
      }
    },
    //设置灯光开关状态
    async setLightState(params) {
      try {
        let parms = JSON.stringify(params);
        console.log(parms);
        reqestAPI.setLightKT(parms);
        // this.lightparm = {}
        // this.getLightList()
      } catch (error) {
        console.error(error);
      }
    },
    // 获取灯光状态
    async getLightState() {
      try {
        let res = await reqestAPI.getLightState(this.room_id);
        if (res.data == 0) {
          this.isLightfull = false;
        } else {
          this.isLightfull = true;
        }
        // console.log('获取灯光状态', res.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.light_main {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    font-family: PingFangSC_Semibold;
    line-height: 28px;
    background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .all_light {
    background-image: url(@/assets/images/room/curtain_bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 540px;
    height: 522px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .light_control_list {
      width: 520px;
      height: 330px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      overflow: auto;
      .no_light {
        font-size: 20px;
      }
      .light_item_on,
      .light_item_off {
        width: 240px;
        height: 150px;
        box-sizing: border-box;
        background-image: url("@/assets/images/room/light_bg_on.png");
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 15px 20px 6px 11px;
        margin-top: 10px;
        overflow: hidden;

        .three_light_switch {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .three_light_top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .three_light_name {
              width: 140px;
              font-size: 16px;
              color: #fff;
              white-space: pre-wrap;
              font-family: PingFangSC_Semibold;
              margin-left: 9px;
            }

            .three_light_state {
              width: 50px;
              height: 54px;
            }
          }
          .three_light_bottom {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .three_light_switch_item {
              // width: 24px;
              height: 37px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              // margin-right: 28px;

              .three_light_switch_name {
                font-size: 16px;
                line-height: 16px;
                color: #fff;
                font-family: PingFangSC_Medium;
                transform: scale(0.666);
              }

              .three_light_switch_name1 {
                font-size: 16px;
                line-height: 16px;
                color: #696e78;
                font-family: PingFang_SC_Regular;
                transform: scale(0.666);
              }

              .three_light_switch_btn {
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .two_light_switch {
          display: flex;
          flex-direction: column;
          height: 100%;
          .two_light_top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .two_light_name {
              width: 140px;
              font-size: 16px;
              color: #fff;
              font-family: PingFangSC_Semibold;
              margin-left: 9px;
            }

            .two_light_state {
              width: 50px;
              height: 54px;
            }
          }

          .two_light_bottom {
            display: flex;
            align-items: flex-end;
            .two_light_switch_item {
              // width: 24px;
              height: 37px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 5px;
              margin-right: 22px;

              .two_light_switch_name_on {
                font-size: 16px;
                line-height: 16px;
                color: #fff;
                font-family: PingFangSC_Medium;
                transform: scale(0.666);
              }

              .two_light_switch_name_off {
                font-size: 12px;
                line-height: 12px;
                color: #696e78;
                font-family: PingFang_SC_Regular;
                transform: scale(0.666);
              }

              .two_light_switch_btn {
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .one_light_switch {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .one_light_top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .one_light_name {
              width: 155px;
              overflow: auto;
              line-height: 16px;
              font-size: 16px;
              color: #fff;
              margin-left: 9px;
              font-family: PingFangSC_Semibold;
            }

            .one_light_state_on {
              font-size: 16px;
              color: #009aff;
              font-family: PingFangSC_Medium;
              transform: scale(0.833);
            }

            .one_light_state_off {
              font-size: 16px;
              color: #696e78;
              font-family: PingFang_SC_Regular;
              transform: scale(0.833);
            }
          }

          .one_light_bottom {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            height: 72px;
            .one_light_bottom_state {
              width: 70px;
              height: 72px;
            }

            .one_light_bottom_switch {
              width: 38px;
              height: 38px;
              margin-bottom: 4px;
            }
          }
        }
      }

      .light_item_off {
        background-image: url("@/assets/images/room/light_bg_off.png");

        .one_light_switch {
          .one_light_top {
            .one_light_name {
              font-family: PingFang_SC_Regular;
            }
          }
        }
      }
    }

    .all_light_control_main {
      width: 520px;
      height: 120px;
      background-image: url(@/assets/images/room/light/all_light_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 21px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .left {
        display: flex;
        align-items: center;
        .desc {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 60px;
          margin-left: 5px;

          div:nth-child(1) {
            font-size: 22px;
            color: #fff;
            font-family: PingFangSC_Semibold;
          }

          div:nth-child(2) {
            font-size: 18px;
            color: #fff;
            opacity: 0.6;
            font-family: PingFang_SC_Regular;
          }
        }
      }

      .right {
        margin-left: 36px;

        img {
          width: 144px;
          height: 84px;
        }
      }
    }
  }
}
</style>
