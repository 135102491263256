<template>
  <div>
    <div  class="AQI">
      <h3>空气质量</h3>
      <div v-if="false" class="AQI_list">
        <div v-if="AQI_list.temperature" class="AQI_item">
          <img src="@/assets/images/room/编组 4.png" alt="" />
          <div class="AQI_item_name">温度</div>
          <div class="AQI_item_num">
            {{ AQI_list.temperature.toFixed(1) }}
          </div>
          <!-- <div class="num">22</div> -->
          <div class="AQI_item_per">&#8451;</div>
        </div>
        <div v-if="AQI_list.humidity" class="AQI_item">
          <img src="@/assets/images/room/编组 5.png" alt="" />
          <div class="AQI_item_name">湿度</div>
          <div class="AQI_item_num">{{ AQI_list.humidity }}</div>
          <!-- <div class="num">22</div> -->
          <div class="AQI_item_per">%</div>
        </div>
        <div v-if="AQI_list.pm25" class="AQI_item">
          <img src="@/assets/images/room/编组 6.png" alt="" />
          <div class="AQI_item_name">PM2.5</div>
          <div class="AQI_item_num">{{ AQI_list.pm25 }}</div>
          <!-- <div class="num">22</div> -->
          <div class="AQI_item_per">ug/m³</div>
        </div>
        <div v-if="AQI_list.voc" class="AQI_item">
          <img src="@/assets/images/room/编组 6 (1).png" alt="" />
          <div class="AQI_item_name">voc</div>
          <div class="AQI_item_num">{{ AQI_list.voc }}</div>
          <!-- <div class="num">22</div> -->
          <div class="AQI_item_per">ug/m³</div>
        </div>
        <div v-if="AQI_list.co2" class="AQI_item">
          <img src="@/assets/images/room/编组 8.png" alt="" />
          <div class="AQI_item_name">CO2</div>
          <div class="AQI_item_num">{{ AQI_list.co2 }}</div>
          <!-- <div class="num">22</div> -->
          <div class="AQI_item_per">ppm</div>
        </div>
        <div v-if="AQI_list.formaldehyd" class="AQI_item">
          <img src="@/assets/images/room/编组 9.png" alt="" />
          <div class="AQI_item_name">甲醛</div>
          <div class="AQI_item_num">{{ AQI_list.formaldehyd }}</div>
          <!-- <div class="num">22</div> -->
          <div class="AQI_item_per">ug/m³</div>
        </div>
      </div>
      <div  class="AQI_list">
        <div v-if="AQI_list.temperature" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/温度.png" alt="" />
            <div class="AQI_item_name">温度</div>
          </div>
          <div class="AQI_item_num">
            {{ AQI_list.temperature }}
            <span>&#8451;</span>
          </div>
        </div>
        <div v-if="AQI_list.humidity" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/湿度.png" alt="" />
            <div class="AQI_item_name">湿度</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.humidity }}<span>%</span></div>
        </div>
        <div v-if="AQI_list.pm25" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/PM2.5.png" alt="" />
            <div class="AQI_item_name">PM2.5</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.pm25 }}<span>ug/m³</span></div>
        </div>
        <div v-if="AQI_list.voc" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/VOC.png" alt="" />
            <div class="AQI_item_name">voc</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.voc }}<span>ug/m³</span></div>
        </div>
        <div v-if="AQI_list.co2" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/CO₂ .png" alt="" />
            <div class="AQI_item_name">CO2</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.co2 }}<span>ppm</span></div>
        </div>
        <div v-if="AQI_list.formaldehyd" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/甲醛.png" alt="" />
            <div class="AQI_item_name">甲醛</div>
          </div>
          <div class="AQI_item_num">
            {{ AQI_list.formaldehyd }}<span>ug/m³</span>
          </div>
        </div>
      </div>
    </div>
   
    <div v-if="false" class="AQI1">
      <h3>空气质量</h3>
      <div class="AQI_list1">
        <div v-if="AQI_list.temperature" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/温度.png" alt="" />
            <div class="AQI_item_name">温度</div>
          </div>
          <div class="AQI_item_num">
            {{ AQI_list.temperature.toFixed(1) }}
            <span>&#8451;</span>
          </div>
        </div>
        <div v-if="AQI_list.humidity" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/湿度.png" alt="" />
            <div class="AQI_item_name">湿度</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.humidity }}<span>%</span></div>
        </div>
        <div v-if="AQI_list.pm25" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/PM2.5.png" alt="" />
            <div class="AQI_item_name">PM2.5</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.pm25 }}<span>ug/m³</span></div>
        </div>
        <div v-if="AQI_list.voc" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/VOC.png" alt="" />
            <div class="AQI_item_name">voc</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.voc }}<span>ug/m³</span></div>
        </div>
        <div v-if="AQI_list.co2" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/CO₂ .png" alt="" />
            <div class="AQI_item_name">CO2</div>
          </div>
          <div class="AQI_item_num">{{ AQI_list.co2 }}<span>ppm</span></div>
        </div>
        <div v-if="AQI_list.formaldehyd" class="AQI_item">
          <div class="left">
            <img src="../../../assets/images/new_room/甲醛.png" alt="" />
            <div class="AQI_item_name">甲醛</div>
          </div>
          <div class="AQI_item_num">
            {{ AQI_list.formaldehyd }}<span>ug/m³</span>
          </div>
        </div>
      </div>
      <div v-if="devicesState['6'] !== '0'" class="guangzhao">
        <h3>光照度</h3>
        <div class="line"></div>
        <div class="guangzhao_main">
          <div class="left">
            <img src="../../../assets/images/new_room/stack-light.png" alt="" />
            <div class="AQI_item_name">光照度</div>
          </div>
          <div class="AQI_item_num">
            {{ IlluminationList.Illuminance }}
            <span>Lux</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reqestAPI from "@/service/api/index";
export default {
  name: "AQI",
  props: ["roomInfo"],
  data() {
    return {
      AQI_list: [], //空气质量列表
      // IlluminationTimer: null,
      // IlluminationList: [], //光照数据
    };
  },
  created() {
   this.AQI_list = JSON.parse(this.roomInfo.tmpAndHList[0].deviceStatus)
   console.log('this.AQI_listthis.AQI_listthis.AQI_listthis.AQI_list',this.AQI_list)
    // this.getSensorsList(); //空气质量
    // this.getIllumination(); // 获取光照强度
    // this.IlluminationTimer = setInterval(() => {
    //   this.getIllumination(this.room_id); //空气质量
    //   this.getIllumination(); // 获取光照强度
    // }, 1000 * 60 * 60);
  },
  methods: {
    // //获取空气质量数据
    // async getSensorsList() {
    //   try {
    //     let roomId = this.$route.query.roomID;
    //     let res = await reqestAPI.getSensors(roomId);
    //     if (res.data[0]) {
    //       this.AQI_list = JSON.parse(res.data[0].sensor_status);
    //     }
    //     console.log('获取空气质量数据', res.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // // 获取光照强度
    // async getIllumination() {
    //   try {
    //     let res = await reqestAPI.getIllumination(this.room_id);
    //     if (res.code == 200) {
    //       if (res.data.length) {
    //         this.IlluminationList = JSON.parse(res.data[0].illumination_status);
    //       }
    //     }
    //     // console.log('获取光照强度', JSON.parse(res.data[0].illumination_status));
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
  beforeDestroy() {
    clearInterval(this.IlluminationTimer);
  },
};
</script>

<style lang="less" scoped>
.AQI,
.AQI1 {
  width: 290px;
  height: 622px;
  background-image: url("../../../assets/images/new_room/底框.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.AQI1 {
  height: 440px;
  position: relative;
  .AQI_list1 {
    overflow: auto;
  }
}
.AQI_list,
.AQI_list1 {
  margin-top: 21px;
  .AQI_item {
    width: 270px;
    height: 82px;
    background-image: url("../../../assets/images/new_room/api_item_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    .left {
      display: flex;
      align-items: center;
      position: absolute;
      left: 15px;
      img {
        width: 40px;
        height: 40px;
      }
      .AQI_item_name {
        margin-left: 15px;
        font-size: 16px;
        color: #fff;
        font-family: PingFangSC_Semibold;
      }
    }
    .AQI_item_num {
      position: absolute;
      left: 148px;
      font-size: 28px;
      color: #fff;
      font-family: HarmonyOS_Sans_SC;
      span {
        font-size: 12px;
        color: #fff;
        font-family: PingFang_SC_Regular;
        opacity: 0.6;
      }
    }

    .AQI_item_per {
    }
  }
}

.guangzhao {
  background-image: url("../../../assets/images/new_room/矩形.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 290px;
  height: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  position: absolute;
  bottom: -172px;
  .line {
    width: 270px;
    height: 2px;
    margin-top: 10px;
    background-image: url("../../../assets/images/new_room/line.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .guangzhao_main {
    margin-top: 8px;
    width: 270px;
    height: 82px;
    background-image: url("../../../assets/images/new_room/api_item_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    .left {
      display: flex;
      align-items: center;
      position: absolute;
      left: 15px;
      img {
        width: 40px;
        height: 40px;
      }
      .AQI_item_name {
        margin-left: 15px;
        font-size: 16px;
        color: #fff;
        font-family: PingFangSC_Semibold;
      }
    }
    .AQI_item_num {
      position: absolute;
      left: 148px;
      font-size: 28px;
      color: #fff;
      font-family: HarmonyOS_Sans_SC;
      span {
        font-size: 12px;
        color: #fff;
        font-family: PingFang_SC_Regular;
        opacity: 0.6;
      }
    }
  }
}
h3 {
  font-size: 20px;
  color: #fff;
  font-family: PingFangSC_Semibold;
  line-height: 28px;
  background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 10px;
}
</style>
