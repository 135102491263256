<template>
  <div>
    <!-- 1 -->
    <div v-if="false" class="kt1">
      <h3>空调控制</h3>
      <div class="kt_list">
        <div class="kt_item" v-for="(item, index) in onlineAirList">
          <div class="kt_kongzhi">
            <div class="top">
              <div class="Kt_name">
                {{ item.deviceName || "" }}
              </div>
              <div class="kt_left">
                <div class="kt_feng">
                  <img
                    class="feng_lv"
                    src="../../../assets/images/new_room/风速等级.png"
                    alt=""
                  />
                  <div
                    v-if="item.deviceStatus.ktSwitch == 'OFF'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'hight'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'medium'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'low'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <img
                    v-else-if="item.deviceStatus.ktFan == 'auto'"
                    class="feng_anto"
                    src="../../../assets/images/new_room/自动.png"
                    alt=""
                  />
                </div>
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? `${item.deviceStatus.ktModel}`
                      : 'mode_off'
                  "
                >
                  {{ ktState[item.deviceStatus.ktModel] }}
                </div>
                <div class="kt_state">{{ item.deviceStatus.ktSwitch }}</div>
              </div>
            </div>
            <div class="center">
              <div class="sub">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.sub"
                  src="../../../assets/images/new_room/温度-减-点击.png"
                  alt=""
                />
                <img
                  @click="subKtTemperature(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  src="../../../assets/images/new_room/温度-减-开.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="subKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-减-关.png"
                  alt=""
                />
              </div>
              <div
                :class="item.deviceStatus.ktSwitch == 'ON' ? 'num' : 'num_off'"
              >
                {{ item.deviceStatus.ktSetTemperatureInt }}°C
              </div>
              <div class="add">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.add"
                  src="../../../assets/images/new_room/温度-加-点击.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-开.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-关.png"
                  alt=""
                />
              </div>
            </div>
            <div class="bottom">
              <div class="btn">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.mode"
                  src="../../../assets/images/new_room/空调-模式-点击.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="setktmode(item, index)"
                  src="../../../assets/images/new_room/空调-模式-开.png"
                  alt=""
                />
                <img
                  @click="setktmode(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  src="../../../assets/images/new_room/空调-模式-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  模式
                </div>
              </div>
              <div class="btn">
                <img
                  src="../../../assets/images/new_room/空调-风速-点击.png"
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.feng"
                  alt=""
                />
                <img
                  src="../../../assets/images/new_room/空调-风速-开.png"
                  @click="setktFeng(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="setktFeng(item, index)"
                  src="../../../assets/images/new_room/空调-风速-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  风速
                </div>
              </div>
              <div class="btn">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="ktopen(item, index)"
                  src="../../../assets/images/new_room/空调-开关-开.png"
                  alt=""
                />
                <img
                  v-else
                  @click="ktopen(item, index)"
                  src="../../../assets/images/new_room/空调-开关-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  开关
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="kt2">
      <h3>空调控制</h3>
      <div class="kt_list">
        <div class="kt_item" v-for="(item, index) in onlineAirList">
          <img
            v-if="item.deviceStatus === null"
            class="kt_img"
            :src="ktimg.off"
            alt=""
          />
          <img
            v-else
            class="kt_img"
            :src="
              item.deviceStatus.ktSwitch == 'ON'
                ? ktimg[item.deviceStatus.ktModel]
                : ktimg.off
            "
            alt=""
          />
          <div v-if="item.deviceStatus === null" class="kt_kongzhi">
            <div class="top">
              <div class="Kt_name">
                {{ item.deviceName || "" }}
              </div>
              <div class="kt_left">
                <div class="kt_feng">
                  <img
                    class="feng_lv"
                    src="../../../assets/images/new_room/风速等级.png"
                    alt=""
                  />
                  <div class="feng_state">
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                </div>
                <div class="mode_off">制冷</div>
                <div class="kt_state">OFF</div>
              </div>
            </div>
            <div class="center">
              <div class="sub">
                <img
                  @click="subKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-减-关.png"
                  alt=""
                />
              </div>
              <div class="num_off">27°C</div>
              <div class="add">
                <img
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-关.png"
                  alt=""
                />
              </div>
            </div>
            <div class="bottom">
              <div class="btn">
                <img
                  @click="setktmode(item, index)"
                  src="../../../assets/images/new_room/空调-模式-关.png"
                  alt=""
                />
                <div class="btn_name_off">模式</div>
              </div>
              <div class="btn">
                <img
                  @click="setktFeng(item, index)"
                  src="../../../assets/images/new_room/空调-风速-关.png"
                  alt=""
                />
                <div class="btn_name_off">风速</div>
              </div>
              <div class="btn">
                <img
                  @click="ktopen(item, index)"
                  src="../../../assets/images/new_room/空调-开关-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  开关
                </div>
              </div>
            </div>
          </div>
          <div v-else class="kt_kongzhi">
            <div class="top">
              <div class="Kt_name">
                {{ item.deviceName || "" }}
              </div>
              <div class="kt_left">
                <div class="kt_feng">
                  <img
                    class="feng_lv"
                    src="../../../assets/images/new_room/风速等级.png"
                    alt=""
                  />
                  <div
                    v-if="item.deviceStatus.ktSwitch == 'OFF'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'hight'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'medium'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'low'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <img
                    v-else-if="item.deviceStatus.ktFan == 'auto'"
                    class="feng_anto"
                    src="../../../assets/images/new_room/自动.png"
                    alt=""
                  />
                </div>
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? `${item.deviceStatus.ktModel}`
                      : 'mode_off'
                  "
                >
                  {{ ktState[item.deviceStatus.ktModel] }}
                </div>
                <div class="kt_state">{{ item.deviceStatus.ktSwitch }}</div>
              </div>
            </div>
            <div class="center">
              <div class="sub">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.sub&&btnState.id==item.id"
                  src="../../../assets/images/new_room/温度-减-点击.png"
                  alt=""
                />
                <img
                  @click="subKtTemperature(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  src="../../../assets/images/new_room/温度-减-开.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="subKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-减-关.png"
                  alt=""
                />
              </div>
              <div
                :class="item.deviceStatus.ktSwitch == 'ON' ? 'num' : 'num_off'"
              >
                {{ item.deviceStatus.ktSetTemperatureInt }}°C
              </div>
              <div class="add">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.add&&btnState.id==item.id"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-点击.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-开.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-关.png"
                  alt=""
                />
              </div>
            </div>
            <div class="bottom">
              <div class="btn">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.mode&&btnState.id==item.id"
                  src="../../../assets/images/new_room/空调-模式-点击.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="setktmode(item, index)"
                  src="../../../assets/images/new_room/空调-模式-开.png"
                  alt=""
                />
                <img
                  @click="setktmode(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  src="../../../assets/images/new_room/空调-模式-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  模式
                </div>
              </div>
              <div class="btn">
                <img
                  src="../../../assets/images/new_room/空调-风速-点击.png"
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.feng&&btnState.id==item.id"
                  alt=""
                />
                <img
                  src="../../../assets/images/new_room/空调-风速-开.png"
                  @click="setktFeng(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="setktFeng(item, index)"
                  src="../../../assets/images/new_room/空调-风速-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  风速
                </div>
              </div>
              <div class="btn">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="ktopen(item, index)"
                  src="../../../assets/images/new_room/空调-开关-开.png"
                  alt=""
                />
                <img
                  v-else
                  @click="ktopen(item, index)"
                  src="../../../assets/images/new_room/空调-开关-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  开关
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div v-if="false" lass="kt3">
      <h3>空调控制</h3>
      <div class="kt_list">
        <div class="kt_item" v-for="(item, index) in onlineAirList">
          <img
            class="kt_img"
            :src="
              item.deviceStatus.ktSwitch == 'ON'
                ? ktimg[item.deviceStatus.ktModel]
                : ktimg.off
            "
            alt=""
          />
          <div class="kt_kongzhi">
            <div class="top">
              <div class="Kt_name">
                {{ item.deviceName || "" }}
              </div>
              <div class="kt_left">
                <div class="kt_feng">
                  <img
                    class="feng_lv"
                    src="../../../assets/images/new_room/风速等级.png"
                    alt=""
                  />
                  <div
                    v-if="item.deviceStatus.ktSwitch == 'OFF'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'hight'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'medium'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <div
                    v-else-if="item.deviceStatus.ktFan == 'low'"
                    class="feng_state"
                  >
                    <img src="../../../assets/images/new_room/1.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                    <img src="../../../assets/images/new_room/3.png" alt="" />
                  </div>
                  <img
                    v-else-if="item.deviceStatus.ktFan == 'auto'"
                    class="feng_anto"
                    src="../../../assets/images/new_room/自动.png"
                    alt=""
                  />
                </div>
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? `${item.deviceStatus.ktModel}`
                      : 'mode_off'
                  "
                >
                  {{ ktState[item.deviceStatus.ktModel] }}
                </div>
                <div class="kt_state">{{ item.deviceStatus.ktSwitch }}</div>
              </div>
            </div>
            <div class="center">
              <div class="sub">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.sub"
                  src="../../../assets/images/new_room/温度-减-点击.png"
                  alt=""
                />
                <img
                  @click="subKtTemperature(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  src="../../../assets/images/new_room/温度-减-开.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="subKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-减-关.png"
                  alt=""
                />
              </div>
              <div
                :class="item.deviceStatus.ktSwitch == 'ON' ? 'num' : 'num_off'"
              >
                {{ item.deviceStatus.ktSetTemperatureInt }}°C
              </div>
              <div class="add">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.add"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-点击.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-开.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="addKtTemperature(item, index)"
                  src="../../../assets/images/new_room/温度-加-关.png"
                  alt=""
                />
              </div>
            </div>
            <div class="bottom">
              <div class="btn">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.mode"
                  src="../../../assets/images/new_room/空调-模式-点击.png"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="setktmode(item, index)"
                  src="../../../assets/images/new_room/空调-模式-开.png"
                  alt=""
                />
                <img
                  @click="setktmode(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  src="../../../assets/images/new_room/空调-模式-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  模式
                </div>
              </div>
              <div class="btn">
                <img
                  src="../../../assets/images/new_room/空调-风速-点击.png"
                  v-if="item.deviceStatus.ktSwitch == 'ON' && btnState.feng"
                  alt=""
                />
                <img
                  src="../../../assets/images/new_room/空调-风速-开.png"
                  @click="setktFeng(item, index)"
                  v-else-if="item.deviceStatus.ktSwitch == 'ON'"
                  alt=""
                />
                <img
                  v-else-if="item.deviceStatus.ktSwitch == 'OFF'"
                  @click="setktFeng(item, index)"
                  src="../../../assets/images/new_room/空调-风速-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  风速
                </div>
              </div>
              <div class="btn">
                <img
                  v-if="item.deviceStatus.ktSwitch == 'ON'"
                  @click="ktopen(item, index)"
                  src="../../../assets/images/new_room/空调-开关-开.png"
                  alt=""
                />
                <img
                  v-else
                  @click="ktopen(item, index)"
                  src="../../../assets/images/new_room/空调-开关-关.png"
                  alt=""
                />
                <div
                  :class="
                    item.deviceStatus.ktSwitch == 'ON'
                      ? 'btn_name'
                      : 'btn_name_off'
                  "
                >
                  开关
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTAPI from "../../../service/api/new_api";
import { Message } from "element-ui";
export default {
  name: "KONGTIAO",
  data() {
    return {
      room_id: "",
      //空调图片
      ktimg: {
        heat: require("@/assets/images/new_room/空调制热-开.png"),
        cold: require("@/assets/images/new_room/空调制冷-开.png"),
        wind: require("@/assets/images/new_room/空调制冷-开.png"),
        wet: require("@/assets/images/new_room/空调制冷-开.png"),
        off: require("@/assets/images/new_room/空调-关.png"),
      },
      //空调状态
      ktState: {
        heat: "制热",
        cold: "制冷",
        wind: "送风",
        wet: "除湿",
      },
      //空调风速
      KtList: [], //空调列表
      KtDtailList: [], //空调详情列表
      timer: null,
      btnState: {
        add: false,
        sub: false,
        mode: false,
        feng: false,
        id:''
      }, //按钮点击状态
      // ws: null,
      // path: `ws://192.168.3.200:30000/device/websocket`,
    };
  },
  props: ["onlineAirList"],
  created() {
    this.onlineAirList.map((item) => {
      if (typeof item.deviceStatus == "string") {
        item.deviceStatus = JSON.parse(item.deviceStatus);
      }
    });
    console.log("空调数据=====>", this.onlineAirList);
  },
  methods: {
    //空调温度减少
    async subKtTemperature(ktitem, index) {
      try {
        let res;
        if (ktitem.deviceStatus === null) {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: 27,
            ktModel: "cold",
            ktFan: "auto",
            ktSwitch: "ON",
          });
          if (res.code == 200 && res.data.resData) {
            if (res.data.resData.status == 400) {
              Message.warning(res.data.resData.msg);
              this.btnState.sub = false;
              return;
            }
            this.$set(
              this.onlineAirList[index],
              "deviceStatus",
              res.data.resData.data
            );
          }
          return;
        }
        this.btnState.sub = true;
        this.btnState.id = ktitem.id;
        if (ktitem.deviceStatus.ktSwitch == "OFF") {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: ktitem.deviceStatus.ktSetTemperatureInt - 1,
            ktSwitch: "ON",
          });
        } else {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: ktitem.deviceStatus.ktSetTemperatureInt - 1,
          });
        }
        if (res.code == 200 && res.data.resData) {
          if (res.data.resData.status == 400) {
            Message.warning(res.data.resData.msg);
            this.btnState.sub = false;
            return;
          }
          this.$set(
            this.onlineAirList[index],
            "deviceStatus",
            res.data.resData.data
          );
        }
        this.btnState.sub = false;
      } catch (error) {
        this.btnState.sub = false;
        console.error(error);
      }
    },
    //空调温度增加
    async addKtTemperature(ktitem, index) {
      try {
        let res;
        if (ktitem.deviceStatus === null) {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: 27,
            ktModel: "cold",
            ktFan: "auto",
            ktSwitch: "ON",
          });
          if (res.code == 200 && res.data.resData) {
            if (res.data.resData.status == 400) {
              Message.warning(res.data.resData.msg);
              this.btnState.sub = false;
              return;
            }
            this.$set(
              this.onlineAirList[index],
              "deviceStatus",
              res.data.resData.data
            );
          }
          return;
        }
        this.btnState.add = true;
        this.btnState.id = ktitem.id;
        if (ktitem.deviceStatus.ktSwitch == "OFF") {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: ktitem.deviceStatus.ktSetTemperatureInt + 1,
            ktSwitch: "ON",
          });
        } else {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: ktitem.deviceStatus.ktSetTemperatureInt + 1,
          });
        }
        if (res.code == 200 && res.data.resData) {
          if (res.data.resData.status == 400) {
            Message.warning(res.data.resData.msg);
            this.btnState.add = false;
            return;
          }
          this.$set(
            this.onlineAirList[index],
            "deviceStatus",
            res.data.resData.data
          );
        }
        this.btnState.add = false;
      } catch (error) {
        this.btnState.add = false;
        console.error(error);
      }
    },
    //设置空调模式
    async setktmode(ktitem, index) {
      try {
        let state;
        let res;
        if (ktitem.deviceStatus === null) {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: 27,
            ktModel: "cold",
            ktFan: "auto",
            ktSwitch: "ON",
          });
          if (res.code == 200 && res.data.resData) {
            if (res.data.resData.status == 400) {
              Message.warning(res.data.resData.msg);
              this.btnState.sub = false;
              return;
            }
            this.$set(
              this.onlineAirList[index],
              "deviceStatus",
              res.data.resData.data
            );
          }
          return;
        }
        this.btnState.mode = true;
        this.btnState.id = ktitem.id;
        if (ktitem.ktModel == "heat") {
          state = "cold";
        } else if (ktitem.deviceStatus.ktModel == "cold") {
          state = "wind";
        } else if (ktitem.deviceStatus.ktModel == "wind") {
          state = "wet";
        } else if (ktitem.deviceStatus.ktModel == "wet") {
          state = "heat";
        } else {
          state = "cold";
        }
        if (ktitem.deviceStatus.ktSwitch == "OFF") {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktModel: state,
            ktSwitch: "ON",
          });
        } else {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktModel: state,
          });
        }
        this.btnState.mode = false;
        if (res.code == 200 && res.data.resData) {
          this.$set(
            this.onlineAirList[index],
            "deviceStatus",
            res.data.resData.data
          );
        } else {
          Message.warning(res.data.resData.msg);
        }
      } catch (error) {
        this.btnState.mode = false;
        console.error(error);
      }
    },
    //控制空调开关
    async ktopen(ktitem, index) {
      try {
        if (ktitem.deviceStatus === null) {
          let res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktSwitch: "ON",
          });
          if (res.code == 200 && res.data.resData) {
            if (res.data.resData.status == 400) {
              Message.warning(res.data.resData.msg);
              this.btnState.sub = false;
              return;
            }
            this.$set(
              this.onlineAirList[index],
              "deviceStatus",
              res.data.resData.data
            );
          }
          return;
        }
        let res = await KTAPI.setDevice({
          cmdAction: 11,
          bodyType: 0,
          deviceId: ktitem.id,
          ktSwitch: ktitem.deviceStatus.ktSwitch == "ON" ? "OFF" : "ON",
        });
        if (res.code == 200 && res.data.resData) {
          this.$set(
            this.onlineAirList[index],
            "deviceStatus",
            res.data.resData.data
          );
        } else {
          Message.warning(res.data.resData.msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    //设置空调风速
    async setktFeng(ktitem, index) {
      try {
        let state;
        let res;
        if (ktitem.deviceStatus === null) {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktTemperatureInt: 27,
            ktModel: "cold",
            ktFan: "auto",
            ktSwitch: "ON",
          });
          if (res.code == 200 && res.data.resData) {
            if (res.data.resData.status == 400) {
              Message.warning(res.data.resData.msg);
              this.btnState.sub = false;
              return;
            }
            this.$set(
              this.onlineAirList[index],
              "deviceStatus",
              res.data.resData.data
            );
          }
          return;
        }
        this.btnState.feng = true;
        this.btnState.id = ktitem.id;
        if (ktitem.deviceStatus.ktFan == "low") {
          state = "medium";
        } else if (ktitem.deviceStatus.ktFan == "medium") {
          state = "hight";
        } else if (ktitem.deviceStatus.ktFan == "hight") {
          state = "auto";
        } else if (ktitem.deviceStatus.ktFan == "auto") {
          state = "low";
        } else {
          state = "low";
        }
        if (ktitem.deviceStatus.ktSwitch == "OFF") {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktFan: state,
            ktSwitch: "ON",
          });
        } else {
          res = await KTAPI.setDevice({
            cmdAction: 11,
            bodyType: 0,
            deviceId: ktitem.id,
            ktFan: state,
          });
        }
        if (res.code == 200 && res.data.resData) {
          this.$set(
            this.onlineAirList[index],
            "deviceStatus",
            res.data.resData.data
          );
        } else {
          Message.warning(res.data.resData.msg);
        }
        this.btnState.feng = false;
      } catch (error) {
        console.error(error);
        this.btnState.feng = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.kt_list {
  margin-top: 20px;
  // height: 540px;
  overflow: auto;
  max-height: 540px;
  .kt_item,
  .kt_item_off {
    width: 580px;
    height: 206px;
    background-image: url("../../../assets/images/new_room/kt_on.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding: 0 20px;
    .kt_img {
      width: 166px;
      height: 166px;
    }
    .kt_kongzhi {
      width: 100%;
      height: 100%;
      margin-left: 40px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 18px;
        height: 24px;
        .Kt_name {
          width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          line-height: 24px;
          text-align: left;
          font-style: normal;
        }
        .kt_left {
          display: flex;
          align-items: center;
          .kt_feng {
            display: flex;
            align-items: center;
            .feng_lv {
              width: 16px;
              height: 16px;
            }
            .feng_state {
              display: flex;
              align-items: center;
              img {
                margin-left: 2px;
                width: 20px;
                height: 6px;
              }
            }
            .feng_anto {
              width: 37px;
              margin-left: 8px;
              height: 12px;
            }
          }
          .heat,
          .cold,
          .wind,
          .wet,
          .mode_off {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #009aff;
            line-height: 20px;
            text-align: center;
            font-style: normal;
            margin-left: 16px;
          }
          .heat {
            color: #ff4a00;
          }
          .mode_off {
            color: #696e78;
          }
          .kt_state {
            margin-left: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
            text-align: center;
            font-style: normal;
          }
        }
      }
      .center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .sub,
        .add {
          img {
            width: 50px;
            height: 50px;
          }
        }
        .num,
        .num_off {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 40px;
          color: #ffffff;
          line-height: 40px;
          text-align: right;
          font-style: normal;
        }
        .num_off {
          color: #696e78;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
          }
          .btn_name,
          .btn_name_off {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
          .btn_name_off {
            color: #696e78;
          }
        }
      }
    }
  }
  .kt_item_off {
    background-image: url("../../../assets/images/new_room/kt_off.png");
  }
  .kt_item:nth-child(1),
  .kt_item_off:nth-child(1) {
    margin-top: 0;
  }
}
.kt2,
.kt1,
.kt3 {
  width: 600px;
  height: 622px;
  background-image: url("../../../assets/images/new_room/底框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    line-height: 28px;
    background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
    font-weight: 700;
    font-family: PingFangSC_Semibold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.kt1 {
  width: 290px;
  .kt_list {
    .kt_item,
    .kt_item_off {
      width: 270px;
      padding: 0 10px;
      .kt_kongzhi {
        margin: 0;
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 18px;
          height: 24px;
          .Kt_name {
            font-size: 14px;
          }
          .kt_left {
            .heat,
            .cold,
            .wind,
            .wet,
            .mode_off {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
.kt3 {
  width: 910px;
  .kt_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-around;
    .kt_item,
    .kt_item_off {
      padding: 0 10px;
      width: 440px;
      .kt_img {
        width: 150px;
        height: 150px;
      }
      .kt_kongzhi {
        margin: 0;
        width: 230px;
        width: 250px;
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 18px;
          height: 24px;
          .Kt_name {
            font-size: 14px;
          }
          .kt_left {
            .heat,
            .cold,
            .wind,
            .wet,
            .mode_off {
              white-space: nowrap;
            }
          }
        }
      }
    }
    .kt_item:nth-child(2),
    .kt_item_off:nth-child(2) {
      margin-top: 0;
    }
  }
}
</style>
