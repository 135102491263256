<template>
  <div>
    <div class="WENSHI">
      <h3>温度湿度</h3>
      <div class="wenshi_list">
        <div
          v-for="(item, index) in roomInfo.tmpAndHList"
          :key="index"
          class="wenshi_item"
        >
          <div class="title">{{ item.deviceName }}</div>
          <div class="AQI_item">
            <div class="left">
              <img src="../../../assets/images/new_room/温度.png" alt="" />
              <div class="AQI_item_name">温度</div>
            </div>
            <div class="AQI_item_num">
              {{ item.deviceStatus===null?'--':item.deviceStatus.temperature }}<span>&#8451;</span>
            </div>
          </div>
          <div class="AQI_item">
            <div class="left">
              <img src="../../../assets/images/new_room/湿度.png" alt="" />
              <div class="AQI_item_name">湿度</div>
            </div>
            <div class="AQI_item_num">
              {{ item.deviceStatus===null?'--':item.deviceStatus.humidity }}<span>%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WENSHIDU",
  props: ["roomInfo"],
  data() {
    return {
      Timer: null,
      room_id: "",
      deviceIdArr: [], //设备id集合
    };
  },
  created() {
    this.roomInfo.tmpAndHList.map((item) => {
      if (typeof item.deviceStatus == "string") {
        item.deviceStatus = JSON.parse(item.deviceStatus);
      }
      // this.deviceIdArr.push(item.id);
      // this.deviceIdArr = [...new Set(this.deviceIdArr)];
    });
    // this.SET_WS(this.deviceIdArr)
    console.log("空气质量数据=====>", this.roomInfo.tmpAndHList);
  },
  methods: {
    SET_WS(data) {
      this.sendMsg(data, (ev) => {
        console.log("空气质量ws相关数据====>",ev);
      });
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
.WENSHI {
  width: 290px;
  height: 622px;
  background-image: url("../../../assets/images/new_room/底框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  h3 {
    font-size: 20px;
    color: #fff;
    font-family: PingFangSC_Semibold;
    line-height: 28px;
    background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 10px;
  }
}
.wenshi_list {
  width: 290px;
  height: 550px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  .wenshi_item {
    width: 270px;
    height: 172px;
    background-image: url("../../../assets/images/new_room/wenshidu_item_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 20px;
    margin-top: 10px;
    .title {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #ffffff;
      line-height: 32px;
      text-align: left;
      font-style: normal;
      margin-left: 20px;
    }
  }
  .wenshi_item:nth-child(1),
  .wenshi_item:nth-child(2) {
    margin-top: 0;
  }
  .AQI_item {
    width: 240px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-top: 20px;
    .left {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
      }
      .AQI_item_name {
        margin-left: 15px;
        font-size: 16px;
        color: #fff;
        font-family: PingFangSC_Semibold;
      }
    }
    .AQI_item_num {
      font-size: 28px;
      color: #fff;
      font-family: HarmonyOS_Sans_SC;
      span {
        font-size: 12px;
        color: #fff;
        font-family: PingFang_SC_Regular;
        opacity: 0.6;
        margin-left: 5px;
      }
    }

    .AQI_item_per {
    }
  }
  .AQI_item:nth-child(2) {
    margin-top: 10px;
  }
}
</style>
