import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
import LOGIN from "@/pages/login/login.vue";
import HOME from "@/pages/index.vue";
import ROOM from "@/pages/room/index.vue";
import authRoom from "../pages/auth_room/auth_room.vue";
// import
// import Overall from '@/pages/overall/index.vue'
export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      name: "LOGIN",
      component: LOGIN,
    },
    {
      path: "/home",
      name: "HOME",
      component: HOME,
    },
    {
      path: "/room",
      name: "ROOM",
      component: ROOM,
    },
    {
      path: "/authroom/:type",
      name: "authroom",
      component: authRoom,
    },
    ,
    {
      path: "/:pathMatch(.*)",
      name: "Any",
      redirect: "/404",
    },
    // {
    //   path: "/overall",
    //   name: "Overall",
    //   component: Overall,
    // },
  ],
});