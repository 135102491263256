<template>
  <div>
    <!-- 新版占比1 -->
    <div v-if="false" class="deng_one">
      <div class="deng1">
        <h3>灯光控制</h3>
        <div class="deng_main">
          <div class="all_control">
            <div class="left">
              <img
                class="all_deng"
                v-if="isLightfull"
                src="../../../assets/images/new_room/灯-开.png"
                alt=""
              />
              <img
                class="all_deng"
                v-else
                src="../../../assets/images/new_room/关灯.png"
                alt=""
              />
              <div class="desc">灯光总控</div>
              <div class="btn">
                <div
                  @click="setAllLigt"
                  style="color: rgba(255, 255, 255, 0.2)"
                  :class="isLightfull ? '' : 'off'"
                  class="ico"
                >
                  <img
                    v-if="isLightfullLoading && !isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>OFF</span>
                </div>
                <div
                  @click="setAllLigt"
                  class="ico"
                  :class="isLightfull ? 'on' : ''"
                >
                  <img
                    v-if="isLightfullLoading && isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>ON</span>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="all_light_control_main_bottom">
                <p>光照强度</p>
                <img
                  class="guangzhao_bg"
                  src="../../../assets/images/new_room/光照强度底框.png"
                  alt=""
                />
                <div class="num_tip">
                  <span>10%</span>
                  <!-- <span>25%</span> -->
                  <span>50%</span>
                  <!-- <span>75%</span> -->
                  <span>100%</span>
                </div>
                <!--  -->
                <div class="slider_main">
                  <van-slider
                    v-model="sliderValue"
                    active-color="#ffffff00"
                    inactive-color="#ffffff00"
                    :step="50"
                    class="slider_main_item"
                    @change="onChange"
                  >
                    <template #button>
                      <van-button color="#ffffff00" class="custom_button">
                        <img
                          class="custom_button"
                          src="../../../assets/images/new_room/光照强度滑动按钮.png"
                          alt=""
                        />
                      </van-button>
                    </template>
                  </van-slider>
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" class="all_control1">
            <div class="left">
              <img
                class="all_deng"
                v-if="isLightfull"
                src="../../../assets/images/new_room/灯-开.png"
                alt=""
              />
              <img
                class="all_deng"
                v-else
                src="../../../assets/images/new_room/关灯.png"
                alt=""
              />
              <div class="desc">灯光总控</div>
              <div class="btn">
                <div
                  @click="setAllLigt"
                  style="color: rgba(255, 255, 255, 0.2)"
                  :class="isLightfull ? '' : 'off'"
                  class="ico"
                >
                  <img
                    v-if="isLightfullLoading && !isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>OFF</span>
                </div>
                <div
                  @click="setAllLigt"
                  class="ico"
                  :class="isLightfull ? 'on' : ''"
                >
                  <img
                    v-if="isLightfullLoading && isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>ON</span>
                </div>
              </div>
            </div>
          </div>
          <div class="deng_list">
            <div
              :class="[
                item.num == 1 ? 'deng_item1' : 'deng_item',
                item.num == 1 && item.state == 'ON'
                  ? ''
                  : item.num == 2 && isLightimg(item)
                  ? ''
                  : item.num == 3 && isLightimg(item)
                  ? ''
                  : 'deng_item_off',
              ]"
              class="ditem"
              v-for="item in lightList"
              :key="item.light_key"
            >
              <div v-if="item.num == 1" class="one_deng">
                <div class="top">
                  <div>
                    {{ item.light_name }}
                  </div>
                  <div :class="item.state == 'ON' ? 'on' : 'off'">
                    {{ item.state }}
                  </div>
                </div>
                <div class="bottom">
                  <img
                    v-if="item.state == 'ON'"
                    src="../../../assets/images/new_room/开icon.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关icon.png"
                    alt=""
                  />
                  <img
                    v-if="item.state == 'ON'"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
              </div>
              <div v-else-if="item.num == 2" class="more_deng1">
                <div class="left">
                  <div class="title">
                    {{ item.light_name }}
                  </div>
                  <img
                    v-if="isLightimg(item)"
                    src="../../../assets/images/new_room/开icon.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关icon.png"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="item">
                    <div :class="item.state_l1 == 'ON' ? 'title' : 'off'">
                      左开关
                    </div>
                    <img
                      v-if="item.state_l1 == 'ON'"
                      @click="handlertwolight(item, 1)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlertwolight(item, 1)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                  <div class="item">
                    <div :class="item.state_l2 == 'ON' ? 'title' : 'off'">
                      右开关
                    </div>
                    <img
                      v-if="item.state_l2 == 'ON'"
                      @click="handlertwolight(item, 2)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlertwolight(item, 2)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div v-else-if="item.num == 3" class="more_deng">
                <div class="left">
                  <div class="title">{{ item.light_name }}</div>
                  <img
                    v-if="isLightimg(item)"
                    src="../../../assets/images/new_room/开icon.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关icon.png"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="item">
                    <div :class="item.state_left == 'ON' ? 'title' : 'off'">
                      {{ item.state_left }}
                    </div>
                    <img
                      v-if="item.state_left == 'ON'"
                      @click="handlerThreeLight(item, 1)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlerThreeLight(item, 1)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                  <div class="item">
                    <div :class="item.state_center == 'ON' ? 'title' : 'off'">
                      {{ item.state_center }}
                    </div>
                    <img
                      v-if="item.state_center == 'ON'"
                      @click="handlerThreeLight(item, 2)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlerThreeLight(item, 2)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                  <div class="item">
                    <div :class="item.state_right == 'ON' ? 'title' : 'off'">
                      {{ item.state_right }}
                    </div>
                    <img
                      v-if="item.state_right == 'ON'"
                      @click="handlerThreeLight(item, 3)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlerThreeLight(item, 3)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dian_dain">
          <img src="../../../assets/images/new_room/dian_1.png" alt="" />
          <img src="../../../assets/images/new_room/dian_2.png" alt="" />
          <img src="../../../assets/images/new_room/dian_1.png" alt="" />
        </div>
      </div>
      <!-- 光照 -->
      <div v-if="false" class="guangzhao">
        <h3>光照度</h3>
        <div class="line"></div>
        <div class="guangzhao_main">
          <div class="left">
            <img src="../../../assets/images/new_room/stack-light.png" alt="" />
            <div class="AQI_item_name">光照度</div>
          </div>
          <div class="AQI_item_num">
            {{ IlluminationList.Illuminance }}
            <span>Lux</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 新版占比1 含光照 -->
    <div v-if="false" class="deng_one">
      <div class="deng1">
        <h3>灯光控制</h3>
        <div class="deng_main">
          <div class="all_control">
            <div class="left">
              <img
                class="all_deng"
                v-if="isLightfull"
                src="../../../assets/images/new_room/灯-开.png"
                alt=""
              />
              <img
                class="all_deng"
                v-else
                src="../../../assets/images/new_room/关灯.png"
                alt=""
              />
              <div class="desc">灯光总控</div>
              <div class="btn">
                <div
                  @click="setAllLigt"
                  style="color: rgba(255, 255, 255, 0.2)"
                  :class="isLightfull ? '' : 'off'"
                  class="ico"
                >
                  <img
                    v-if="isLightfullLoading && !isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>OFF</span>
                </div>
                <div
                  @click="setAllLigt"
                  class="ico"
                  :class="isLightfull ? 'on' : ''"
                >
                  <img
                    v-if="isLightfullLoading && isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>ON</span>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="all_light_control_main_bottom">
                <p>光照强度</p>
                <img
                  class="guangzhao_bg"
                  src="../../../assets/images/new_room/光照强度底框.png"
                  alt=""
                />
                <div class="num_tip">
                  <span>10%</span>
                  <!-- <span>25%</span> -->
                  <span>50%</span>
                  <!-- <span>75%</span> -->
                  <span>100%</span>
                </div>
                <!--  -->
                <div class="slider_main">
                  <van-slider
                    v-model="sliderValue"
                    active-color="#ffffff00"
                    inactive-color="#ffffff00"
                    :step="50"
                    class="slider_main_item"
                    @change="onChange"
                  >
                    <template #button>
                      <van-button color="#ffffff00" class="custom_button">
                        <img
                          class="custom_button"
                          src="../../../assets/images/new_room/光照强度滑动按钮.png"
                          alt=""
                        />
                      </van-button>
                    </template>
                  </van-slider>
                </div>
              </div>
            </div>
          </div>
          <div v-if="false" class="all_control1">
            <div class="left">
              <img
                class="all_deng"
                v-if="isLightfull"
                src="../../../assets/images/new_room/灯-开.png"
                alt=""
              />
              <img
                class="all_deng"
                v-else
                src="../../../assets/images/new_room/关灯.png"
                alt=""
              />
              <div class="desc">灯光总控</div>
              <div class="btn">
                <div
                  @click="setAllLigt"
                  style="color: rgba(255, 255, 255, 0.2)"
                  :class="isLightfull ? '' : 'off'"
                  class="ico"
                >
                  <img
                    v-if="isLightfullLoading && !isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>OFF</span>
                </div>
                <div
                  @click="setAllLigt"
                  class="ico"
                  :class="isLightfull ? 'on' : ''"
                >
                  <img
                    v-if="isLightfullLoading && isLightfull"
                    class="wait"
                    src="../../../assets/images/new_room/等待.png"
                    alt=""
                  />
                  <span v-else>ON</span>
                </div>
              </div>
            </div>
          </div>
          <div class="deng_list">
            <div
              :class="[
                item.num == 1 ? 'deng_item1' : 'deng_item',
                item.num == 1 && item.state == 'ON'
                  ? ''
                  : item.num == 2 && isLightimg(item)
                  ? ''
                  : item.num == 3 && isLightimg(item)
                  ? ''
                  : 'deng_item_off',
              ]"
              class="ditem"
              v-for="item in lightList"
              :key="item.light_key"
            >
              <div v-if="item.num == 1" class="one_deng">
                <div class="top">
                  <div>
                    {{ item.light_name }}
                  </div>
                  <div :class="item.state == 'ON' ? 'on' : 'off'">
                    {{ item.state }}
                  </div>
                </div>
                <div class="bottom">
                  <img
                    v-if="item.state == 'ON'"
                    src="../../../assets/images/new_room/开icon.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关icon.png"
                    alt=""
                  />
                  <img
                    v-if="item.state == 'ON'"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
              </div>
              <div v-else-if="item.num == 2" class="more_deng1">
                <div class="left">
                  <div class="title">
                    {{ item.light_name }}
                  </div>
                  <img
                    v-if="isLightimg(item)"
                    src="../../../assets/images/new_room/开icon.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关icon.png"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="item">
                    <div :class="item.state_l1 == 'ON' ? 'title' : 'off'">
                      左开关
                    </div>
                    <img
                      v-if="item.state_l1 == 'ON'"
                      @click="handlertwolight(item, 1)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlertwolight(item, 1)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                  <div class="item">
                    <div :class="item.state_l2 == 'ON' ? 'title' : 'off'">
                      右开关
                    </div>
                    <img
                      v-if="item.state_l2 == 'ON'"
                      @click="handlertwolight(item, 2)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlertwolight(item, 2)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div v-else-if="item.num == 3" class="more_deng">
                <div class="left">
                  <div class="title">{{ item.light_name }}</div>
                  <img
                    v-if="isLightimg(item)"
                    src="../../../assets/images/new_room/开icon.png"
                    alt=""
                  />
                  <img
                    v-else
                    src="../../../assets/images/new_room/关icon.png"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="item">
                    <div :class="item.state_left == 'ON' ? 'title' : 'off'">
                      {{ item.state_left }}
                    </div>
                    <img
                      v-if="item.state_left == 'ON'"
                      @click="handlerThreeLight(item, 1)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlerThreeLight(item, 1)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                  <div class="item">
                    <div :class="item.state_center == 'ON' ? 'title' : 'off'">
                      {{ item.state_center }}
                    </div>
                    <img
                      v-if="item.state_center == 'ON'"
                      @click="handlerThreeLight(item, 2)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlerThreeLight(item, 2)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                  <div class="item">
                    <div :class="item.state_right == 'ON' ? 'title' : 'off'">
                      {{ item.state_right }}
                    </div>
                    <img
                      v-if="item.state_right == 'ON'"
                      @click="handlerThreeLight(item, 3)"
                      src="../../../assets/images/new_room/开-1.png"
                      alt=""
                    />
                    <img
                      v-else
                      @click="handlerThreeLight(item, 3)"
                      src="../../../assets/images/new_room/关-1.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dian_dain">
          <img src="../../../assets/images/new_room/dian_1.png" alt="" />
          <img src="../../../assets/images/new_room/dian_2.png" alt="" />
          <img src="../../../assets/images/new_room/dian_1.png" alt="" />
        </div>
      </div>
      <!-- 光照 -->
      <div v-if="false" class="guangzhao">
        <h3>光照度</h3>
        <div class="line"></div>
        <div class="guangzhao_main">
          <div class="left">
            <img src="../../../assets/images/new_room/stack-light.png" alt="" />
            <div class="AQI_item_name">光照度</div>
          </div>
          <div class="AQI_item_num">
            {{ IlluminationList.Illuminance }}
            <span>Lux</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 新版占比3 -->
    <div class="deng3">
      <h3>灯光控制</h3>
      <div class="deng_main">
        <div v-if="false" class="all_control">
          <div class="left">
            <img
              class="all_deng"
              v-if="isLightfull"
              src="../../../assets/images/new_room/灯-开.png"
              alt=""
            />
            <img
              class="all_deng"
              v-else
              src="../../../assets/images/new_room/关灯.png"
              alt=""
            />
            <div class="desc">灯光总控</div>
            <div class="btn">
              <div
                @click="setAllLigt"
                style="color: rgba(255, 255, 255, 0.2)"
                :class="isLightfull ? '' : 'off'"
                class="ico"
              >
                <img
                  v-if="isLightfullLoading && !isLightfull"
                  class="wait"
                  src="../../../assets/images/new_room/等待.png"
                  alt=""
                />
                <span v-else>OFF</span>
              </div>
              <div
                @click="setAllLigt"
                class="ico"
                :class="isLightfull ? 'on' : ''"
              >
                <img
                  v-if="isLightfullLoading && isLightfull"
                  class="wait"
                  src="../../../assets/images/new_room/等待.png"
                  alt=""
                />
                <span v-else>ON</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="all_light_control_main_bottom">
              <p>光照强度</p>
              <img
                class="guangzhao_bg"
                src="../../../assets/images/new_room/光照强度底框.png"
                alt=""
              />
              <div class="num_tip">
                <span>10%</span>
                <!-- <span>25%</span> -->
                <span>50%</span>
                <!-- <span>75%</span> -->
                <span>100%</span>
              </div>
              <!--  -->
              <div class="slider_main">
                <van-slider
                  v-model="sliderValue"
                  active-color="#ffffff00"
                  inactive-color="#ffffff00"
                  :step="50"
                  class="slider_main_item"
                  @change="onChange"
                >
                  <template #button>
                    <van-button color="#ffffff00" class="custom_button">
                      <img
                        class="custom_button"
                        src="../../../assets/images/new_room/光照强度滑动按钮.png"
                        alt=""
                      />
                    </van-button>
                  </template>
                </van-slider>
              </div>
            </div>
          </div>
        </div>
        <div v-if="false" class="all_control1">
          <div class="left">
            <img
              class="all_deng"
              v-if="isLightfull"
              src="../../../assets/images/new_room/灯-开.png"
              alt=""
            />
            <img
              class="all_deng"
              v-else
              src="../../../assets/images/new_room/关灯.png"
              alt=""
            />
            <div class="desc">灯光总控</div>
            <div class="btn">
              <div
                @click="setAllLigt"
                style="color: rgba(255, 255, 255, 0.2)"
                :class="isLightfull ? '' : 'off'"
                class="ico"
              >
                <img
                  v-if="isLightfullLoading && !isLightfull"
                  class="wait"
                  src="../../../assets/images/new_room/等待.png"
                  alt=""
                />
                <span v-else>OFF</span>
              </div>
              <div
                @click="setAllLigt"
                class="ico"
                :class="isLightfull ? 'on' : ''"
              >
                <img
                  v-if="isLightfullLoading && isLightfull"
                  class="wait"
                  src="../../../assets/images/new_room/等待.png"
                  alt=""
                />
                <span v-else>ON</span>
              </div>
            </div>
          </div>
        </div>
        <div class="deng_list" style="margin-top: 20px">
          <div
            class="deng_item"
            v-for="(item, index) in lightList"
            :key="item.id"
          >
            <div v-if="item.num == 0" class="one_deng">
              <div class="top">
                <div>
                  {{ item.light_name }}
                </div>
                <div class="off">OFF</div>
              </div>
              <div class="bottom">
                <img src="../../../assets/images/new_room/关icon.png" alt="" />
                <img
                  @click="handleronelight(item, index)"
                  src="../../../assets/images/new_room/关-1.png"
                  alt=""
                />
              </div>
            </div>
            <div v-else-if="item.num == 1" class="one_deng">
              <div class="top">
                <div>
                  {{ item.deviceName }}
                </div>
                <div :class="item.deviceStatus.state == 'ON' ? 'on' : 'off'">
                  {{ item.state }}
                </div>
              </div>
              <div class="bottom">
                <img
                  v-if="item.deviceStatus.state == 'ON'"
                  src="../../../assets/images/new_room/开icon.png"
                  alt=""
                />
                <img
                  v-else
                  src="../../../assets/images/new_room/关icon.png"
                  alt=""
                />
                <img
                  v-if="item.deviceStatus.state == 'ON'"
                  @click="handleronelight(item, index)"
                  src="../../../assets/images/new_room/开-1.png"
                  alt=""
                />
                <img
                  v-else
                  @click="handleronelight(item, index)"
                  src="../../../assets/images/new_room/关-1.png"
                  alt=""
                />
              </div>
            </div>
            <div v-else-if="item.num == 2" class="more_deng more_deng_2">
              <div class="top">
                <div class="title">
                  {{ item.deviceName }}
                </div>
                <img
                  v-if="isLightimg(item)"
                  src="../../../assets/images/new_room/开icon.png"
                  alt=""
                />
                <img
                  v-else
                  src="../../../assets/images/new_room/关icon.png"
                  alt=""
                />
              </div>
              <div class="bottom">
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_l1 == 'ON' ? 'title' : 'off'
                    "
                  >
                    左开关
                  </div>
                  <img
                    v-if="item.deviceStatus.state_l1 == 'ON'"
                    @click="handlertwolight(item, 1, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlertwolight(item, 1, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
                <div class="item">
                  <div :class="item.state_l2 == 'ON' ? 'title' : 'off'">
                    右开关
                  </div>
                  <img
                    v-if="item.state_l2 == 'ON'"
                    @click="handlertwolight(item, 2, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlertwolight(item, 2, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div v-else-if="item.num == 3" class="more_deng more_deng_3">
              <div class="top">
                <div class="title">
                  {{ item.deviceName }}
                </div>
                <img
                  v-if="isLightimg(item)"
                  src="../../../assets/images/new_room/开icon.png"
                  alt=""
                />
                <img
                  v-else
                  src="../../../assets/images/new_room/关icon.png"
                  alt=""
                />
              </div>
              <div class="bottom">
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_left == 'ON' ? 'title' : 'off'
                    "
                  >
                    左开关
                  </div>
                  <img
                    v-if="item.deviceStatus.state_left == 'ON'"
                    @click="handlerThreeLight(item, 1, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlerThreeLight(item, 1, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_center == 'ON' ? 'title' : 'off'
                    "
                  >
                    中间开关
                  </div>
                  <img
                    v-if="item.deviceStatus.state_center == 'ON'"
                    @click="handlerThreeLight(item, 2, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlerThreeLight(item, 2, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_right == 'ON' ? 'title' : 'off'
                    "
                  >
                    右开关
                  </div>
                  <img
                    v-if="item.deviceStatus.state_right == 'ON'"
                    @click="handlerThreeLight(item, 3, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlerThreeLight(item, 3, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div v-else-if="item.num == 4" class="more_deng more_deng_4">
              <div class="top">
                <div class="title">
                  {{ item.deviceName }}
                </div>
                <img
                  v-if="isLightimg(item)"
                  src="../../../assets/images/new_room/开icon.png"
                  alt=""
                />
                <img
                  v-else
                  src="../../../assets/images/new_room/关icon.png"
                  alt=""
                />
              </div>
              <div class="bottom">
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_l1 == 'ON' ? 'title' : 'off'
                    "
                  >
                    开关一
                  </div>
                  <img
                    v-if="item.deviceStatus.state_l1 == 'ON'"
                    @click="handlerFourLight(item, 1, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlerFourLight(item, 1, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_l2 == 'ON' ? 'title' : 'off'
                    "
                  >
                    开关二
                  </div>
                  <img
                    v-if="item.deviceStatus.state_l2 == 'ON'"
                    @click="handlerFourLight(item, 2, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlerFourLight(item, 2, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_l3 == 'ON' ? 'title' : 'off'
                    "
                  >
                    开关三
                  </div>
                  <img
                    v-if="item.deviceStatus.state_l3 == 'ON'"
                    @click="handlerFourLight(item, 3, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlerFourLight(item, 3, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
                <div class="item">
                  <div
                    :class="
                      item.deviceStatus.state_l4 == 'ON' ? 'title' : 'off'
                    "
                  >
                    开关四
                  </div>
                  <img
                    v-if="item.deviceStatus.state_l4 == 'ON'"
                    @click="handlerFourLight(item, 4, index)"
                    src="../../../assets/images/new_room/开-1.png"
                    alt=""
                  />
                  <img
                    v-else
                    @click="handlerFourLight(item, 4, index)"
                    src="../../../assets/images/new_room/关-1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reqestAPI from "@/service/api/index";
import { Message } from "element-ui";
import LIGHTAPI from "../../../service/api/new_api";
export default {
  name: "LIGHTS",
  props: ["lightList",'getRoomList'],
  data() {
    return {
      isLightfull: false, //灯光全开全关
      isLightfullLoading: false, //灯光全开全关状态
      IlluminationList: [], //光照数据
      IlluminationTimer: null, //光照强度定时器
      timer: null,
      sliderValue: 0,
    };
  },
  created() {
    this.lightList.map((item) => {
      if (typeof item.deviceStatus == "string") {
        item.deviceStatus = JSON.parse(item.deviceStatus);
      }

      if (item.deviceStatus === null) {
        item.num = 0;
      } else if (item.deviceStatus.state_l4) {
        item.num = 4;
      } else if (item.deviceStatus.state_left) {
        item.num = 3;
      } else if (item.deviceStatus.state_l2) {
        item.num = 2;
      } else if (item.deviceStatus.state) {
        item.num = 1;
      }
    });
    console.log("灯光数据=====>", this.lightList);
    // this.getIllumination(); // 获取光照强度
    // }, 3000);
    // this.IlluminationTimer = setInterval(() => {
    //   this.getSensorsList(this.room_id); // 获取光照强度
    // }, 1000 * 60 * 60);
  },
  methods: {
    //设置灯光开关状态
    async setLightState(params, index) {
      try {
        let res = await LIGHTAPI.setDevice(params);
        this.getRoomList()
        console.log("设置灯光开关状态", res, index);
      } catch (error) {
        console.error(error);
      }
    },
    //单个开关
    handleronelight(item, index) {
      let params;
      if (item.deviceStatus.state === "ON") {
        params = {
          lightLeft: "OFF",
        };
      } else {
        params = {
          lightLeft: "ON",
        };
      }
      this.setLightState(
        {
          ...params,
          deviceId: item.id,
          cmdAction: 3,
        },
        index
      );
    },
    //两个开关
    handlertwolight(item, num, index) {
      if (num === 1) {
        if (item.deviceStatus.state_l1 === "ON") {
          params = {
            lightLeft: "OFF",
          };
        } else {
          params = {
            lightLeft: "ON",
          };
        }
      } else if (num === 2) {
        if (item.deviceStatus.state_l2 === "ON") {
          params = {
            lightRight: "OFF",
          };
        } else {
          params = {
            lightRight: "ON",
          };
        }
      }
      this.setLightState(
        {
          ...params,
          deviceId: item.id,
          cmdAction: 3,
        },
        index
      );
    },
    //三个开关
    handlerThreeLight(item, num, index) {
      let params;
      if (num === 1) {
        if (item.deviceStatus.state_left === "ON") {
          params = {
            lightLeft: "OFF",
          };
        } else {
          params = {
            lightLeft: "ON",
          };
        }
      } else if (num === 2) {
        if (item.deviceStatus.state_center === "ON") {
          params = {
            lightCenter: "OFF",
          };
        } else {
          params = {
            lightCenter: "ON",
          };
        }
      } else if (num === 3) {
        if (item.deviceStatus.state_right === "ON") {
          params = {
            lightRight: "OFF",
          };
        } else {
          params = {
            lightRight: "ON",
          };
        }
      }
      this.setLightState(
        {
          ...params,
          deviceId: item.id,
          cmdAction: 3,
        },
        index
      );
    },
    //四个开关
    handlerFourLight(item, num, index) {
      let params;
      if (num === 1) {
        if (item.deviceStatus.state_l1 === "ON") {
          params = {
            lightLeft: "OFF",
          };
        } else {
          params = {
            lightLeft: "ON",
          };
        }
      } else if (num === 2) {
        if (item.deviceStatus.state_l2 === "ON") {
          params = {
            lightCenter: "OFF",
          };
        } else {
          params = {
            lightCenter: "ON",
          };
        }
      } else if (num === 3) {
        if (item.deviceStatus.state_l3 === "ON") {
          params = {
            lightRight: "OFF",
          };
        } else {
          params = {
            lightRight: "ON",
          };
        }
      } else if (num === 4) {
        if (item.deviceStatus.state_l4 === "ON") {
          params = {
            lightLast: "OFF",
          };
        } else {
          params = {
            lightLast: "ON",
          };
        }
      }
      this.setLightState(
        {
          ...params,
          deviceId: item.id,
          cmdAction: 3,
        },
        index
      );
    },
    //灯光右上角图片状态
    isLightimg(item) {
      if (item.num === 4) {
        if (
          item.deviceStatus.state_l1 == "ON" ||
          item.deviceStatus.state_l2 == "ON" ||
          item.deviceStatus.state_l3 == "ON" ||
          item.deviceStatus.state_l4 == "ON"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (item.num === 3) {
        if (
          item.deviceStatus.state_left == "ON" ||
          item.deviceStatus.state_center == "ON" ||
          item.deviceStatus.state_right == "ON"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (item.num === 2) {
        if (
          item.deviceStatus.state_l1 == "ON" ||
          item.deviceStatus.state_l2 == "ON"
        ) {
          return true;
        } else {
          return false;
        }
      } else if (item.num === 1) {
        if (item.deviceStatus.state == "ON") {
          return true;
        } else {
          return false;
        }
      }
    },
    // 设置房间灯光亮度
    async setOutLight(value) {
      try {
        let res = await reqestAPI.setOutLight({
          light_room_id: this.room_id,
          light_function: value,
        });
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    },
    // 获取设置的灯光亮度等级
    async getOutLight(value) {
      try {
        let res = await reqestAPI.getOutLight();
        if (res.code == 200) {
          if (res.data.config_value === 1) {
            this.sliderValue = 0;
          } else if (res.data.config_value === 2) {
            this.sliderValue = 25;
          } else if (res.data.config_value === 3) {
            this.sliderValue = 50;
          } else if (res.data.config_value === 4) {
            this.sliderValue = 75;
          } else if (res.data.config_value === 5) {
            this.sliderValue = 100;
          }
        }
        console.log(res.data);
      } catch (error) {
        console.error(error);
      }
    },
    onChange(value) {
      let light_function;
      if (value === 0) {
        light_function = 2;
      } else if (value === 50) {
        light_function = 3;
      } else if (value === 50) {
        light_function = 5;
      }
      // if (value === 0) {
      //   light_function = 1;
      // } else if (value === 25) {
      //   light_function = 2;
      // } else if (value === 50) {
      //   light_function = 3;
      // } else if (value === 75) {
      //   light_function = 4;
      // } else if (value === 100) {
      //   light_function = 5;
      // }
      // console.log(light_function)
      // this.setOutLight(light_function);
    },
    // 讲解模式开关
    async handlerMode(type) {
      try {
        this.jiang_jie_mode = !this.jiang_jie_mode;
        if (this.jiang_jie_mode) {
          this.setOutLight(2);
          this.setAllCurtain(2);
        } else {
          this.setOutLight(5);
          this.setAllCurtain(1);
          console.log("讲解模式关");
        }
      } catch (error) {}
    },
    // // 设置房间灯光亮度
    // async setOutLight(value) {
    //   try {
    //     let res = await reqestAPI.setOutLight({
    //       light_room_id: this.room_id,
    //       light_function: value,
    //     });
    //     console.log(res);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // // 获取设置的灯光亮度等级
    // async getOutLight(value) {
    //   try {
    //     let res = await reqestAPI.getOutLight();
    //     if (res.code == 200) {
    //       if (res.data.config_value === 1) {
    //         this.sliderValue = 0;
    //       } else if (res.data.config_value === 2) {
    //         this.sliderValue = 25;
    //       } else if (res.data.config_value === 3) {
    //         this.sliderValue = 50;
    //       } else if (res.data.config_value === 4) {
    //         this.sliderValue = 75;
    //       } else if (res.data.config_value === 5) {
    //         this.sliderValue = 100;
    //       }
    //     }
    //     console.log(res.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // onChange(value) {
    //   let light_function;
    //   if (value === 0) {
    //     light_function = 1;
    //   } else if (value === 25) {
    //     light_function = 2;
    //   } else if (value === 50) {
    //     light_function = 3;
    //   } else if (value === 75) {
    //     light_function = 4;
    //   } else if (value === 100) {
    //     light_function = 5;
    //   }
    //   // console.log(light_function)
    //   this.setOutLight(light_function);
    // },
    //设置灯光全开全关状态
    async setAllLigt() {
      try {
        this.isLightfull = !this.isLightfull;
        this.isLightfullLoading = true;
        setTimeout(() => {
          this.isLightfullLoading = false;
        }, 5000);
      } catch (error) {
        this.isLightfullLoading = false;
      }
    },

    // 获取灯光状态
    async getLightState() {
      try {
        let res = await reqestAPI.getLightState(this.room_id);
        if (res.data == 0) {
          this.isLightfull = false;
        } else {
          this.isLightfull = true;
        }
        // console.log('获取灯光状态', res.data);
      } catch (error) {
        console.error(error);
      }
    },
    // 获取光照强度
    async getIllumination() {
      try {
        let res = await reqestAPI.getIllumination(this.room_id);
        if (res.code == 200) {
          if (res.data.length) {
            this.IlluminationList = JSON.parse(res.data[0].illumination_status);
          }
        }
        // console.log('获取光照强度', JSON.parse(res.data[0].illumination_status));
      } catch (error) {
        console.error(error);
      }
    },
  },
  beforeDestroy() {
    this.isLightfullLoading = false;
    clearInterval(this.timer);
    clearInterval(this.IlluminationTimer);
  },
};
</script>

<style lang="less" scoped>
.guangzhao {
  background-image: url("../../../assets/images/new_room/矩形.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 290px;
  height: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .line {
    width: 270px;
    height: 2px;
    margin-top: 10px;
    background-image: url("../../../assets/images/new_room/line.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .guangzhao_main {
    margin-top: 8px;
    width: 270px;
    height: 82px;
    background-image: url("../../../assets/images/new_room/api_item_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    .left {
      display: flex;
      align-items: center;
      position: absolute;
      left: 15px;
      img {
        width: 40px;
        height: 40px;
      }
      .AQI_item_name {
        margin-left: 15px;
        font-size: 16px;
        color: #fff;
        font-family: PingFangSC_Semibold;
      }
    }
    .AQI_item_num {
      position: absolute;
      left: 148px;
      font-size: 28px;
      color: #fff;
      font-family: HarmonyOS_Sans_SC;
      span {
        font-size: 12px;
        color: #fff;
        font-family: PingFang_SC_Regular;
        opacity: 0.6;
      }
    }
  }
}
.deng_one {
  h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    line-height: 28px;
    background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
    font-weight: 700;
    font-family: PingFangSC_Semibold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .deng1 {
    width: 290px;
    height: 450px;
    background-image: url("../../../assets/images/new_room/灯光控制底框.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    .dian_dain {
      margin-top: 5px;
      img {
        width: 8px;
        height: 4px;
      }
      img:nth-child(2) {
        margin: 0 10px;
      }
    }
    .deng_main {
      .deng_list {
        display: flex;
        overflow: auto;
        margin-top: 10px;
        width: 270px;

        .deng_item {
          width: 270px;
          background-image: url("../../../assets/images/new_room/deng_bg_on.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .more_deng,
          .more_deng1 {
            width: 270px;
            height: 102px;
            display: flex;
            box-sizing: border-box;
            padding: 0 10px;
            align-items: center;

            .left {
              margin-right: 8px;

              img {
                width: 40px;
                height: 42px;
                margin-top: 10px;
              }

              .title {
                width: 80px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 12px;
                color: #ffffff;
                line-height: 20px;
                text-align: left;
                font-style: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .right {
              width: 162px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .title,
              .on {
                font-family: PingFangSC, PingFang SC;
                white-space: nowrap;
                font-weight: 500;
                font-size: 12px;
                color: #009aff;
                line-height: 20px;
                text-align: center;
                font-style: normal;
              }

              .off {
                color: #fff;
                opacity: 0.4;
              }

              img {
                width: 42px;
                height: 42px;
                margin-top: 10px;
              }
            }
          }

          .more_deng1 {
            .right {
              justify-content: space-around;
              box-sizing: border-box;
              padding-top: 10px;

              img {
                margin-top: 10px;
              }
            }
          }
        }

        .deng_item1 {
          width: 130px;
          height: 102px;
          background-image: url("../../../assets/images/new_room/deng_bg_on.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .one_deng {
            box-sizing: border-box;
            padding: 0 10px;

            .top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: PingFangSC, PingFang SC;
              margin-top: 15px;
              margin-bottom: 10px;

              div:nth-child(1) {
                width: 80px;
                font-weight: 400;
                font-size: 12px;
                color: #ffffff;
                line-height: 24px;
                text-align: left;
                font-style: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .on {
                font-weight: 500;
                font-size: 12px;
                color: #009aff;
                line-height: 20px;
                text-align: center;
                font-style: normal;
                text-transform: uppercase;
              }

              .off {
                color: #fff;
                opacity: 0.4;
              }
            }

            .bottom {
              display: flex;
              align-items: self-end;
              justify-content: space-between;

              img {
                width: 40px;
                height: 42px;
              }
            }
          }
        }

        .deng_item_off {
          background-image: url("../../../assets/images/new_room/deng_bg_off.png");
        }

        .ditem {
          margin-left: 10px;
        }

        .ditem:nth-child(1) {
          margin-left: 0;
        }
      }

      .all_control,
      .all_control1 {
        width: 270px;
        height: 254px;
        background-image: url("../../../assets/images/new_room/总控底框2.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        .left {
          .all_deng,
          .all_deng_off {
            position: absolute;
            top: 20px;
            left: 10px;
            width: 80px;
            height: 80px;
          }

          .desc {
            position: absolute;
            right: 58px;
            top: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }

          .btn {
            position: absolute;
            top: 33px;
            left: 83px;
            width: 198px;
            height: 100px;

            background-image: url("../../../assets/images/new_room/灯光总控底框.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 0 15px;

            .wait {
              width: 24px;
              height: 24px;
              animation: turn 2s linear infinite;
            }

            .ico,
            .off,
            .on {
              width: 94px;
              height: 73px;
              font-family: HarmonyOS_Sans_SC_Black;
              font-size: 18px;
              color: #ffffff;
              line-height: 70px;
              text-align: center;
              font-style: normal;
            }

            // animation: turn 10s linear infinite;
            .on {
              background-image: url("../../../assets/images/new_room/灯光总控底框-开.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .off {
              background-image: url("../../../assets/images/new_room/灯光总控底框-关.png");
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }
          }
        }

        .right {
          margin-top: 141px;

          .all_light_control_main_bottom {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: relative;

            .slider_main {
              position: absolute;
              bottom: 30px;
              left: 35px;
            }

            .slider_main_item {
              width: 210px;
              height: 38px;
            }

            .custom_button {
              border-radius: 50%;
              width: 36px;
              height: 36px;
            }

            // align-items: center;
            .num_tip {
              width: 230px;
              height: 30px;
              margin-left: 28px;
              margin-top: -22px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              span {
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
              }
            }

            .guangzhao_bg {
              width: 280px;
              height: 78px;
              margin-top: -5px;
            }

            p {
              margin-left: 18px;
              margin-top: 10px;
              font-size: 16px;
              color: #fff;
              font-family: PingFangSC_Semibold;
            }
          }
        }
      }

      .all_control1 {
        background-image: url("../../../assets/images/new_room/总控底框1.png");

        .left {
          display: flex;
          align-items: center;

          .all_deng,
          .all_deng_off {
            margin-left: 50px;
          }

          .desc {
            margin: 0 40px;
          }

          .btn {
            margin-left: 360px;
          }
        }
      }
    }
  }
}

.deng3 {
  width: 910px;
  height: 622px;
  background-image: url("../../../assets/images/new_room/底框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;

  h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    line-height: 28px;
    background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
    font-weight: 700;
    font-family: PingFangSC_Semibold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .deng_main {
    .deng_list {
      display: grid;
      grid-template-columns: 290px 290px 290px;
      grid-gap: 10px;
      grid-template-rows: 130px;
      overflow: auto;
      height: 420px;
      margin-top: 10px;

      .deng_item,
      .deng_item_off {
        width: 290px;
        height: 130px;
        background-image: url("../../../assets/images/new_room/deng_bg_on.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .more_deng {
          box-sizing: border-box;
          padding: 10px 20px 15px 20px;
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              width: 160px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
              line-height: 24px;
              text-align: left;
              font-style: normal;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            img {
              width: 38px;
              height: 40px;
            }
          }

          .bottom {
            margin-top: 8px;

            .title,
            .off {
              font-family: PingFangSC, PingFang SC;
              white-space: nowrap;
              font-weight: 500;
              font-size: 12px;
              color: #ffffff;
              line-height: 20px;
              text-align: center;
              font-style: normal;
            }

            .off {
              color: #fff;
              opacity: 0.4;
            }

            img {
              margin-top: 5px;
              width: 32px;
              height: 32px;
            }
          }
        }
        .more_deng_2 {
          padding: 10px 40px 15px 40px;
          .bottom {
            display: grid;
            grid-template-columns: 32px 32px 32px;
            grid-column-gap: 50px;
          }
        }
        .more_deng_3 {
          .bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .item {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
        .more_deng_4 {
          .bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .item {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
          .top {
            img {
              margin-right: 20px;
            }
          }
        }
        .one_deng {
          box-sizing: border-box;
          padding: 20px 40px;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: PingFangSC, PingFang SC;
            margin-bottom: 10px;

            div:nth-child(1) {
              width: 160px;
              font-weight: 400;
              font-size: 16px;
              color: #ffffff;
              line-height: 24px;
              text-align: left;
              font-style: normal;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .on {
              font-weight: 500;
              font-size: 12px;
              color: #009aff;
              line-height: 20px;
              text-align: center;
              font-style: normal;
              text-transform: uppercase;
            }

            .off {
              color: #fff;
              opacity: 0.4;
            }
          }

          .bottom {
            display: flex;
            align-items: self-end;
            justify-content: space-between;
          }
        }
      }

      .deng_item_off {
        background-image: url("../../../assets/images/new_room/deng_bg_off.png");
      }
    }

    .all_control,
    .all_control1 {
      width: 890px;
      height: 132px;
      background-image: url("../../../assets/images/new_room/总控底框.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 20px;
      display: flex;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        .all_deng,
        .all_deng_off {
          width: 92px;
          height: 92px;
        }

        .desc {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          margin: 0 20px;
        }

        .btn {
          width: 198px;
          height: 100px;
          background-image: url("../../../assets/images/new_room/灯光总控底框.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 0 15px;

          .wait {
            width: 24px;
            height: 24px;
            animation: turn 2s linear infinite;
          }

          .ico,
          .off,
          .on {
            width: 94px;
            height: 73px;
            font-family: HarmonyOS_Sans_SC_Black;
            font-size: 18px;
            color: #ffffff;
            line-height: 70px;
            text-align: center;
            font-style: normal;
          }

          // animation: turn 10s linear infinite;
          .on {
            background-image: url("../../../assets/images/new_room/灯光总控底框-开.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .off {
            background-image: url("../../../assets/images/new_room/灯光总控底框-关.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .right {
        margin-left: 37px;

        .all_light_control_main_bottom {
          // width: 437px;
          // height: 78px;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          position: relative;

          .slider_main {
            position: absolute;
            bottom: 30px;
            left: 45px;
          }

          .slider_main_item {
            // position: absolute;
            width: 350px;
            height: 38px;
          }

          .custom_button {
            border-radius: 50%;
            width: 36px;
            height: 36px;
          }

          // align-items: center;
          .num_tip {
            width: 350px;
            height: 30px;
            margin-left: 48px;
            margin-top: -22px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 12px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 20px;
            }
          }

          .guangzhao_bg {
            width: 437px;
            height: 78px;
            margin-top: -5px;
          }

          p {
            margin-left: 18px;
            margin-top: 10px;
            font-size: 16px;
            color: #fff;
            font-family: PingFangSC_Semibold;
          }
        }
      }
    }

    .all_control1 {
      background-image: url("../../../assets/images/new_room/总控底框1.png");

      .left {
        display: flex;
        align-items: center;

        .all_deng,
        .all_deng_off {
          margin-left: 50px;
        }

        .desc {
          margin: 0 40px;
        }

        .btn {
          margin-left: 360px;
        }
      }
    }
  }
}

@keyframes turn {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(72deg);
  }

  40% {
    transform: rotate(144deg);
  }

  60% {
    transform: rotate(216deg);
  }

  80% {
    transform: rotate(288deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
