import Vue from 'vue'
import App from './App.vue'
import './assets/reset.css'
import router from "./router/index";
import store from "./store/index"
import './assets/font.css'
import "vant/lib/index.css";
import { Slider, Button } from "vant";
import { Loading, Input } from "element-ui";

Vue.use(Loading);
Vue.use(Input);
Vue.use(Slider);
Vue.use(Button);
Vue.prototype.$loading = Loading.service;
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
