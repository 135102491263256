import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
//不是在生产环境debug为true
const debug = process.env.NODE_ENV !== "production";
//创建Vuex实例对象
const store = new Vuex.Store({
  strict: debug, //在不是生产环境下都开启严格模式
  mutations: {
    SAVE_ROOM(state, data) {
      state.room = data;
    },
    SAVE_ROOMDEVICES(state, data) {
      state.roomDevices = data;
    },
    CLEAR(state){
        state.room=[]
        state.roomDevices=[]
    }
  },
  state: {
    room: [],
    roomDevices: [],
  },
  actions: {},
  plugins: [
    createPersistedState({
      storage: sessionStorage, //指明存储在sessionStorage中
    }),
  ],
});
export default store;
