<template>
  <div id="home_main">
    <div @click="isfullScreen" class="bgtop">
      <div class="left"></div>
      <div class="right">
        <div class="ymd">{{ timeData.YMD }}</div>
        <div class="week">{{ timeData.week }}</div>
        <div class="hm">{{ timeData.HM }}</div>
      </div>
    </div>
    <div class="content">
      <!-- 房间 -->
      <div class="room_list">
        <div
          :class="index % 2 ? 'item1' : ''"
          class="item"
          v-for="(item, index) in roomInfoList"
          :key="index"
        >
          <div class="room_name">
            {{ item.fullName }}
          </div>
          <div class="room_equipment">
            <div v-if="item.tmpAndHCount > 0" class="equipment_item">
              <img
                v-if="index % 2"
                src="../assets/images/home/空气质量.png"
                alt=""
              />
              <img v-else src="../assets/images/home/空气质量1.png" alt="" />
              <span>空气质量</span>
            </div>
            <div v-if="item.curtainCount > 0" class="equipment_item">
              <img
                v-if="index % 2"
                src="../assets/images/home/窗帘.png"
                alt=""
              />
              <img v-else src="../assets/images/home/窗帘1.png" alt="" />
              <span>窗帘控制</span>
            </div>
            <div v-if="item.lightCount > 0" class="equipment_item">
              <img v-if="index % 2" src="../assets/images/home/灯.png" alt="" />
              <img v-else src="../assets/images/home/灯1.png" alt="" />
              <span>灯光总控</span>
            </div>
            <div
              v-if="item.onLineAirCount > 0 || item.airCount > 0"
              class="equipment_item"
            >
              <img
                v-if="index % 2"
                src="../assets/images/home/光照icon.png"
                alt=""
              />
              <img v-else src="../assets/images/home/光照icon1.png" alt="" />
              <span>空调控制</span>
            </div>
          </div>
          <div class="room_detail">
            <span>查看详情</span>
            <img
              @click="goroom(item,index)"
              v-if="index % 2"
              src="../assets/images/home/路径 (1).png"
              alt=""
            />
            <img
              @click="goroom(item,index)"
              v-else
              src="../assets/images/home/路径.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from "screenfull";
import reqestAPI from "@/service/api/new_api";
export default {
  name: "HOME",
  data() {
    return {
      loading: false,
      AQI_list: [],
      timeData: {
        YMD: "",
        HM: "",
        week: "",
      }, //时间
      timer: null,
      roomInfoList: [],
    };
  },
  created() {
    this.getRoomList(); //获取房间列表
    this.showTimes();
    this.timer = setInterval(() => {
      this.showTimes();
      // this.getRoomList(); //获取房间列表
    }, 10000);
    localStorage.setItem('timer',JSON.stringify(this.timer))
  },

  methods: {
    //获取房间列表
    getRoomList() {
      reqestAPI
        .getRoomsToInfo()
        .then((res) => {
          if (res.code == 200) {
            this.roomInfoList = res.data;
          } else if (res.code == 600) {
            this.$router.push({
              path: "/",
            });
          }
          console.log("获取房间列表", res);
        })
        .catch((rej) => {
          console.error(rej);
        });
    },
    //全屏
    fullScreen() {
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        screenfull.request();
      }
    },
    isfullScreen() {
      //是否全屏
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    },
    getDate(timeStamp) {
      //获取年月日
      // this.loading=true
      let year = new Date(timeStamp).getFullYear();
      let datelist = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      let week = datelist[new Date(timeStamp).getDay()];
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();

      // let ss =
      //   new Date(timeStamp).getSeconds() < 10
      //     ? "0" + new Date(timeStamp).getSeconds()
      //     : new Date(timeStamp).getSeconds();

      this.timeData.YMD = year + "年" + month + "月" + date + "日";
      this.timeData.HM = hh + ":" + mm;
      this.timeData.week = week;
    },
    showTimes() {
      this.getDate(new Date());
    },
    //去房间详情
    goroom(item,index) {
      // console.log(room.id);
      this.$router.push({
        path: "/room",
        query: {
          index: JSON.stringify(index),
        },
      });
    },
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
#home_main {
  width: 1280px;
  height: 800px;
  background-image: url("@/assets/images/home/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bgtop {
    width: 1280px;
    height: 93px;
    background-image: url("@/assets/images/home/bgtop.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: space-between;
    font-family: PingFang_SC_Regular;

    .right {
      margin-top: 20px;
      display: flex;
      align-items: center;
      height: 16px;
      font-size: 12px;
      color: #fff;
      margin-right: 56px;

      .week {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        margin: 0 10px;
        text-align: center;
        width: 56px;
        line-height: 16px;
      }
    }
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .mode {
    width: 1180px;
    display: flex;
    margin-top: 60px;
    justify-content: space-around;
    margin-bottom: 20px;

    .mode_item {
      width: 240px;
      height: 64px;
      background-image: url("@/assets/images/home/mode_bg.png");
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px 0 30px;
      box-sizing: border-box;

      .mode_name {
        font-size: 24px;
        color: #fff;
        font-family: PingFangSC_Semibold;
      }

      .img {
        // width: 87px;
        width: 54px;
        height: 54px;
      }
    }
  }
}

#home_main .room_list {
  width: 1220px;
  height: 582px;
  overflow: auto;
  // height: 500px;
  display: flex;
  flex-wrap: wrap;

  // background-color: #fff;
  .item,
  .item1 {
    width: 290px;
    height: 244px;
    background-image: url(../assets/images/home/item_bg1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .room_name {
      margin-top: 15px;
      height: 32px;
      font-size: 24px;
      font-family: PingFangSC_Semibold;
      font-weight: 600;
      color: #ffffff;
      line-height: 32px;
      background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .room_equipment {
      margin-top: 12px;
      display: flex;
      width: 270px;
      height: 109px;
      flex-wrap: wrap;

      .equipment_item {
        width: 139px;
        height: 57px;
        background-image: url("../assets/images/home/编组 23.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        // justify-content: center;

        img {
          margin-left: 11px;
          width: 34px;
          height: 34px;
        }

        span {
          margin-left: 14px;
          height: 24px;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          opacity: 0.7;
        }
      }

      .equipment_item:nth-child(2),
      .equipment_item:nth-child(4) {
        margin-left: -9px;
      }

      .equipment_item:nth-child(3),
      .equipment_item:nth-child(4) {
        margin-top: -8px;
      }
    }

    .room_detail,
    .room_detail1 {
      width: 269px;
      height: 74px;
      margin-top: -7px;
      background-image: url(../assets/images/home/nav1.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        height: 32px;
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 32px;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
      }

      img {
        width: 58px;
        height: 38px;
        margin-left: 78px;
        cursor: pointer;
      }
    }

    .room_detail1 {
      background-image: url("../assets/images/home/nav.png");
    }
  }

  .item:nth-child(2n + 2) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .item:nth-child(4n + 4) {
    margin-right: 0;
  }

  .item1 {
    // margin-left: 20px;
    background-image: url(../assets/images/home/item_bg.png);

    .room_name {
      background: linear-gradient(180deg, #ffffff 0%, #10d29c 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
