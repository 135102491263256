<template>
  <div class="room">
    <img
      @click="isfullScreen"
      class="bgtop"
      src="@/assets/images/home/bgtop.png"
      alt=""
    />
    <!-- 返回，房间名 -->
    <div class="header">
      <img
        @click="gohome"
        class="back"
        src="@/assets/images/room/back.png"
        alt=""
      />
      <h2>{{ roomInfo.fullName || "" }}</h2>
    </div>
    <!-- 房间详情 -->
    <div class="warp">
      <!-- 空气质量 -->
      <!-- <AQI  :roomInfo="roomInfo"  /> -->
      <!-- 温度湿度 -->
      <WENSHIDU
        style="margin-left: 20px"
        v-if="roomInfo.tmpAndHCount > 0"
        :roomInfo="roomInfo"
      />
      <!-- 空调 -->
      <KONGTIAO
        style="margin-left: 20px"
        v-if="roomInfo.onLineAirCount > 0"
        :onlineAirList="roomInfo.onlineAirList"
      ></KONGTIAO>
      <!-- 灯光 -->
      <LIGHTS
        style="margin-left: 20px"
        v-if="roomInfo.lightCount > 0"
        :getRoomList="getRoomList"
        :lightList="roomInfo.lightList"
      />
      <!-- 窗帘 -->
      <CURTAINS
        style="margin-left: 20px"
        v-if="roomInfo.curtainCount > 0"
        :curtainList="roomInfo.curtainList"
      />

      <!-- 只有灯光 -->
      <!-- <onlyLight  :curtainList="roomInfo.curtainList" /> -->
    </div>
  </div>
</template>

<script>
/**devicesState
 * 1灯光
 * 2
 * 3空气质量
 * 4
 * 5窗帘
 * 6 光照
 */
import roomAPI from "@/service/api/new_api";
import reqestAPI from "@/service/api/index";
import screenfull from "screenfull";
import onlyLight from "../../components/light/onlyLight.vue";
import LIGHTS from "./components/lights.vue";
import CURTAINS from "./components/curtains.vue";
import AQI from "./components/AQI.vue";
import KONGTIAO from "./components/kt.vue";
import WENSHIDU from "./components/wenshidu.vue";
export default {
  name: "ROOM",
  data() {
    return {
      roomInfo: {},
      roomIndex:-1,
      timer: null,
    };
  },
  created() {
    this.roomIndex = JSON.parse(this.$route.query.index);
    console.log("roomIndexroomIndex", this.roomIndex);
    this.getRoomList()
    this.timer = setInterval(() => {
      this.getRoomList(); 
    }, 5*1000*60);
  },
  components: { onlyLight, LIGHTS, CURTAINS, AQI, KONGTIAO, WENSHIDU },
  methods: {
    //获取房间信息
    getRoomList(index) {
      roomAPI
        .getRoomsToInfo()
        .then((res) => {
          if (res.code == 200) {
            this.roomInfo = res.data[this.roomIndex];
          } else if (res.code == 600) {
            this.$router.push({
              path: "/",
            });
          }
          console.log("获取房间信息", this.roomInfo);
        })
        .catch((rej) => {
          console.error(rej);
        });
    },
    //是否全屏
    isfullScreen() {
      if (screenfull.isEnabled) {
        screenfull.toggle();
      }
    },
    //返回首页
    gohome() {
      this.$router.push({
        path: "/home",
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    // clearInterval(this.IlluminationTimer);
  },
};
</script>

<style lang="less" scoped>
.room {
  width: 1280px;
  height: 800px;
  background-image: url("@/assets/images/home/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bgtop {
    width: 1280px;
    height: 93px;
  }

  .header {
    width: 1220px;
    height: 58px;
    background-image: url("@/assets/images/room/编组\ 20.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -16px;

    h2 {
      font-size: 24px;
      color: #fff;
      font-family: FZZCHJW_GB1_0;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    }

    .back {
      position: absolute;
      width: 76px;
      height: 40px;
      left: 20px;
    }
  }

  .warp {
    width: 1220px;
    display: flex;
    // justify-content: center;
    overflow-x: auto;
    // margin-top: 50px;
  }
  //   .warp {
  //   width: 1220px;
  //   display: grid;
  //   grid-template-columns: 290px 910px;
  //   // justify-content: center;
  //   grid-column-gap: 10px;
  //   // margin-top: 50px;
  // }
}
</style>
