import request from "../request";
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Cache-Control": "no-store",
  },
};
export default {
  // 获取房间和设备
  login: (params) => {
    return request.post(
      `/api/oauth/Login?client_id=admin&client_secret=123456&scope=all&grant_type=password`,
      params,
      customConfig
    );
  },
  // 获取房间和设备
  getRoomsToInfo: () => {
    return request.get(`/api/xxfy.iot/IotDevice/getRoomsToInfo`);
  },
  // 设置房间设备
  setDevice: (params) => {
    params.platform = "pad";
    return request.post(
      `/api/xxfy.iot/IotDevice/setSearchDevice/${params.deviceId}`,
      params
    );
  },
};
