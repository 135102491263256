import axios from "axios";

const request = axios.create({
  timeout: 20000, //超时时间
  baseURL: "/", //请求公共路径
  responseType: "json",
});

request.interceptors.request.use((param) => {
  const token = localStorage.getItem("token") || "";
  // 如果存了token，就放在请求头中
  if (token) {
    param.headers["Authorization"] = token;
  }
  return param;
});

request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return new Promise(() => {}); //返回一个不成功，也不是失败的Promise实例
  }
);


export default request