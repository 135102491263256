import request from "../request";
const customConfig = {
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-store",
  },
};
export default {
  userLogin: (parms) => {
    return request.post("/user/login ", parms);
  },
  offLight: () => {
    return request.post("");
  },
  //获取房间列表
  getRoomList: (parms) => {
    return request.get(`/users/${parms.userId}/rooms`);
  },
  // 获取房间场景列表
  getRoomScenes: (roomId) => {
    return request.get(`/users/rooms/newScenes/${roomId}/scenes`);
  },

  // 切换房间场景
  setRoomScenes: (groupId) => {
    return request.post(`/users/rooms/setRoomScene/${groupId}`);
  },
  //切换整个网点场景
  setAllNetWorkScenes: (index) => {
    return request.get(`/users/rooms/allNetwork/${index}`);
  },

  //  获取灯光列表
  getLights: (roomId) => {
    return request.get(`/users/rooms/${roomId}/lights`);
  },
  // 设备调试（灯光，空调）
  setLightKT: (parms) => {
    return request.post(`/mqtt/set`, parms, customConfig);
  },
  //   房间灯光全关或者全开 1 开 2 关
  setAllLigt: (roomId, state) => {
    return request.get(`/users/rooms/set/light/${state}/${roomId}`);
  },
  //  获取传感器数据
  getSensors: (roomId) => {
    return request.get(`/users/rooms/get/${roomId}/sensors`);
  },

  //获得房间空调列表
  getkts: (roomId) => {
    return request.get(`/users/rooms/${roomId}/kts`);
  },
  //  获取房间窗帘列表
  getcurtain: (roomId) => {
    return request.get(`/users/rooms/${roomId}/curtains`);
  },
  //  设置窗帘开关和停止 0 停止 1 打开 2关闭
  Setcurtain: (key, state) => {
    return request.get(`/users/rooms/set/state/${key}/${state}`);
  },
  //  设置窗帘全开全关和停止 0 停止 1 打开 2关闭
  SeAlltcurtain: (roomId, state) => {
    return request.get(`/users/rooms/set/stateRoom/${roomId}/${state}`);
  },
  // // 获取房间设备列表
  // getRoomDevices: (roomId) => {
  //   return request.get(`/users/rooms/${roomId}/devices`);
  // },
  //  GET 光照
  getIllumination: (roomId) => {
    return request.get(`/users/rooms/illumination/find/${roomId}`);
  },
  // 查询房间灯光全关或者全开 data:0 全关 1 有开着的
  getLightState: (roomId) => {
    return request.get(`/users/rooms/get/light/1/${roomId}`);
  },
  // 获取房间设备数量
  getRoomDevices: () => {
    return request.get(`/device/findRoomISNextDevice`);
  },
  // 设置房间灯光亮度
  setOutLight: (params) => {
    return request.post(`/users/rooms/set/outLight`, params);
  },
  // 获取设置的灯光亮度等级
  getOutLight: (params) => {
    return request.get(`/users/rooms/set/item`);
  },
};
