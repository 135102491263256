<template>
  <div id="app">
    <router-view></router-view>
      <!-- <Home /> -->
  </div>
</template>

<script>
import Home from '@/pages/index.vue'
export default {
  name: 'App',
  components: { Home  }
}
</script>

<style>
#app{
  width: 1280px;
  height: 800px;
}
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
