<template>
  <div class="login_main">
    <div class="from">
      <h1>请登录</h1>
      <el-input
        class="input"
        maxlength="20"
        placeholder="请输入账号"
        v-model="account"
        type="text"
      />
      <el-input
        class="input"
        show-password
        v-model="password"
        placeholder="请输入密码"
      />
      <div class="login_btn" @click="handlerLogin">登录</div>
    </div>
  </div>
</template>

<script>
import loginApi from "../../service/api/new_api";
import { Message } from "element-ui";
import md5 from "js-md5";
export default {
  name: "LOGIN",
  data() {
    return {
      account: "",
      password: "",
    };
  },
  mounted() {
    if (localStorage.getItem("timer")) {
      clearInterval(JSON.parse(localStorage.getItem("timer")));
    }
  },
  methods: {
    async handlerLogin() {
      if (this.account.length < 1 || this.password.length < 1) {
        Message.warning("请输入账号或密码");
        return;
      }
      let querey = {
        account: this.account,
        password: md5(this.password),
        origin: "password",
      };
      let res = await loginApi.login(querey);
      console.log("res", res);
      if (res.code == 200) {
        localStorage.setItem("token", res.data.token);
        this.$router.push({
          path: "/home",
        });
      } else {
        Message.warning(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login_main {
  width: 1280px;
  height: 800px;
  background-image: url("@/assets/images/home/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.from {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 400px;
  height: 300px;
  background-image: url("../../assets/images/new_room/总控底框3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-family: FZZCHJW_GB1_0, FZZDHJW--GB1;
    margin-top: 20px;
    font-size: 30px;
    color: #fff;
  }
  .input {
    width: 320px;
    height: 50px;
    margin-top: 20px;
    // background-color: #fff;
    // margin-top: 20px;
    // border-radius: 4px;
    // box-sizing: border-box;
    // padding: 0 12px;
    // font-size: 18px;
  }
  .login_btn {
    width: 320px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    margin-top: 20px;
    background-color: royalblue;
    border-radius: 8px;
    color: #fff;
  }
}
:deep(.el-input__inner) {
  width: 320px;
  height: 50px;
  background-color: #fff;

  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 12px;
  font-size: 18px;
  position: relative;
}
:deep(.el-input__icon) {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 15px;
  right: 0;
}
</style>