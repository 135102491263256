<template>
  <div>
    <!-- 新版窗帘占比1 -->
    <div v-if="false" class="curtain1">
      <h3>窗帘控制</h3>
      <div class="curtain_main">
        <div class="all_curtain">
          <div class="top">窗帘总控</div>
          <div class="bottom">
            <img
              class="button"
              @click="setAllCurtain(2)"
              src="../../../assets/images/new_room/窗帘-关.png"
              alt=""
            />
            <img
              class="button"
              @click="setAllCurtain(0)"
              src="../../../assets/images/new_room/窗帘-暂停.png"
              alt=""
            />
            <img
              class="button"
              @click="setAllCurtain(1)"
              src="../../../assets/images/new_room/窗帘-开.png"
              alt=""
            />
          </div>
        </div>
        <div class="curtain_list">
          <div
            class="curtain_item"
            v-for="(item, index) in curtainList"
            :key="index"
          >
            <!-- <div class="curtain_item" v-for="(item, index) in 10" :key="index"> -->
            <div class="curtain_item_name">
              {{ item.deviceName }}
            </div>
            <div class="curtain_item_bottom">
              <!-- <img
                v-if="bunState.num == 2 && bunState.index == index"
                class="button1"
                @click="setcurtain(2, item, index)"
                src="../../../assets/images/new_room/分区-关-点击.png"
                alt=""
              /> -->
              <img
                class="button1"
                @click="setcurtain(2, item, index)"
                src="../../../assets/images/new_room/窗帘-关.png"
                alt=""
              />
              <img
                class="button1"
                @click="setcurtain(3, item, index)"
                src="../../../assets/images/new_room/窗帘-暂停.png"
                alt=""
              />
              <!-- <img
                v-if="bunState.num == 1 && bunState.index == index"
                class="button1"
                @click="setcurtain(1, item, index)"
                src="../../../assets/images/new_room/分区-开-点击.png"
                alt=""
              /> -->
              <img
                class="button1"
                @click="setcurtain(1, item, index)"
                src="../../../assets/images/new_room/窗帘-开.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新版窗帘占比2 -->
    <div  class="curtain2">
      <h3>窗帘控制</h3>
      <div  class="curtain_main">
        <div v-if="false" class="all_curtain">
          <div  class="left">
            <div class="desc">
              <div class="">窗帘总控</div>
              <div class="">一键管控</div>
            </div>
          </div>
          <div class="right">
            <img
              class="button"
              @click="setAllCurtain(2)"
              src="../../../assets/images/new_room/窗帘-关.png"
              alt=""
            />
            <img
              class="button"
              @click="setAllCurtain(0)"
              src="../../../assets/images/new_room/窗帘-暂停.png"
              alt=""
            />
            <img
              class="button"
              @click="setAllCurtain(1)"
              src="../../../assets/images/new_room/窗帘-开.png"
              alt=""
            />
          </div>
        </div>
        <div style="margin-top: 20px;" class="curtain_list">
          <div
            class="curtain_item"
            v-for="(item, index) in curtainList"
            :key="index"
          >
            <!-- <div class="curtain_item" v-for="(item, index) in 10" :key="index"> -->
            <div class="curtain_item_name">
              {{ item.deviceName }}
            </div>
            <div class="curtain_item_bottom">
              <img
                class="button1"
                @click="setcurtain(2, item, index)"
                src="../../../assets/images/new_room/窗帘-关.png"
                alt=""
              />
              <img
                class="button1"
                @click="setcurtain(3, item, index)"
                src="../../../assets/images/new_room/窗帘-暂停.png"
                alt=""
              />
              <img
                class="button1"
                @click="setcurtain(1, item, index)"
                src="../../../assets/images/new_room/窗帘-开.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reqestAPI from "@/service/api/index";
import CLAPI from "../../../service/api/new_api";
export default {
  name: "CURTAINS",
  props: ["curtainList"],
  data() {
    return {
      timer: null,
      isCurtainfull: false, //窗帘全开全关
      room_id: "",
      IlluminationTimer: null, //光照强度定时器
      bunState: {
        num: "未选择",
        index: "未选择",
      },
    };
  },
  created() {
    this.curtainList.map((item) => {
      if (!item.deviceStatus.moving) {
        item.deviceStatus = JSON.parse(item.deviceStatus);
      }
    });
    console.log("窗帘列表====>", this.curtainList);
  },
  methods: {
    // 设置窗帘状态
    async setcurtain(num, item, index) {
      try {

        if (this.bunState.index === index&&this.bunState.num === num) {
          return
        }
        this.bunState = {
          num: num,
          index: index,
        };
        let res = await CLAPI.setDevice({
          cmdAction: 7,
          curtainCmd: num,
          deviceId: item.id,
        });
      } catch (error) {
        console.error(error);
      }
    },
    //设置窗帘全开全关状态
    async setAllCurtain(num) {
      try {
        let res;
        if (num === 0) {
          res = await reqestAPI.SeAlltcurtain(this.room_id, 0);
        } else if (num === 1) {
          res = await reqestAPI.SeAlltcurtain(this.room_id, 1);
        } else {
          res = await reqestAPI.SeAlltcurtain(this.room_id, 2);
        }
        // this.isCurtainfull = !this.isCurtainfull
        // console.log('设置窗帘状态', res);
      } catch (error) {
        console.error(error);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.curtain1 {
  width: 290px;
  height: 622px;
  background-image: url("../../../assets/images/new_room/底框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    font-family: PingFangSC_Semibold;
    line-height: 28px;
    background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .curtain_list {
    width: 270px;
    height: 400px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .curtain_item {
      width: 270px;
      height: 92px;
      margin-top: 10px;
      background-image: url(../../../assets/images/new_room/api_item_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .curtain_item_name {
        width: 220px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .curtain_item_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 260px;
        height: 84px;
        background-image: url(../../../assets/images/new_room/窗帘分区按钮底座.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 0 20px;
        padding-right: 22px;
        img {
          width: 33px;
          height: 33px;
          text-align: center;
          background-color: rgba(0, 0, 0, 0);
          border-radius: 50%;
          border: none;
          transition: box-shadow 0.3s ease;
          margin-bottom: 3px;
        }
        img:active {
          box-shadow: 0 0 30px rgba(210, 223, 30, 0.842);
          transition: box-shadow 0s ease;
        }
      }
    }
  }
  .all_curtain {
    width: 270px;
    height: 150px;
    background-image: url("../../../assets/images/new_room/总控底框3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 21px;
    box-sizing: border-box;
    padding-top: 20px;
    .top {
      margin-left: 20px;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      font-family: PingFang_SC_Regular;
    }
    .bottom {
      background-image: url(../../../assets/images/new_room/窗帘总控按钮底座.png);
      background-repeat: no-repeat;
      background-size: 100%;
      width: 270px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 20px;
      img {
        width: 55px;
        height: 55px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 50%;
        border: none;
        transition: box-shadow 0.3s ease;
        margin-bottom: 3px;
        // margin-top: 10px;
      }
      img:active {
        box-shadow: 0 0 30px rgba(210, 223, 30, 0.842);
        transition: box-shadow 0s ease;
      }
    }
  }
}
.curtain2 {
  width: 600px;
  height: 622px;
  background-image: url("../../../assets/images/new_room/底框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  h3 {
    font-size: 20px;
    color: #fff;
    margin-top: 10px;
    font-family: PingFangSC_Semibold;
    line-height: 28px;
    background: linear-gradient(180deg, #ffffff 0%, #3a8cff 100%);
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .curtain_list {
    width: 580px;
    height: 400px;
    display: grid;
    margin-top: 10px;
    grid-template-columns: 285px 285px;
    grid-template-rows: 126px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow: auto;
    .curtain_item {
      width: 285px;
      height: 126px;
      background-image: url(../../../assets/images/new_room/api_item_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;

      .curtain_item_name {
        margin-top: 5px;
        margin-left: 20px;
        width: 240px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
        line-height: 20px;
        text-align: left;
        font-style: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .curtain_item_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 285px;
        height: 126px;
        background-image: url(../../../assets/images/new_room/窗帘分区按钮底座.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 0 20px;
        padding-right: 22px;
        img {
          width: 44px;
          height: 44px;
          text-align: center;
          background-color: rgba(0, 0, 0, 0);
          border-radius: 50%;
          border: none;
          transition: box-shadow 0.3s ease;
          margin-bottom: 3px;
        }
        img:active {
          box-shadow: 0 0 30px rgba(210, 223, 30, 0.842);
          transition: box-shadow 0s ease;
        }
      }
    }
  }
  .all_curtain {
    width: 580px;
    height: 150px;
    background-image: url("../../../assets/images/new_room/总控底框1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    margin-top: 21px;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      .desc {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 60px;
        margin-left: 23px;

        div:nth-child(1) {
          font-size: 20px;
          color: #fff;
          font-family: PingFangSC_Semibold;
        }

        div:nth-child(2) {
          font-size: 16px;
          color: #fff;
          opacity: 0.6;
          font-family: PingFang_SC_Regular;
        }
      }
    }
    .right {
      background-image: url(../../../assets/images/new_room/窗帘总控按钮底座.png);
      background-repeat: no-repeat;
      background-size: 100%;
      width: 320px;
      height: 112px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 20px;
      img {
        width: 72px;
        height: 72px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 50%;
        border: none;
        transition: box-shadow 0.3s ease;
        margin-bottom: 3px;
        // margin-top: 10px;
      }
      img:active {
        box-shadow: 0 0 30px rgba(210, 223, 30, 0.842);
        transition: box-shadow 0s ease;
      }
    }
  }
}
</style>
